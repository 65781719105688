<div class="col-12 mt-3 w-100" *ngIf="noticesExist || noticeComponent">
  <div class="card">
    <div class="card-body">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="card-title">Notices</h5>
        <button class="btn btn-secondary m-0 mb-1" (click)="doToggleShowAllNotices()">{{showAllNotices ? "Hide" : "Show"}} All Notices</button>
      </div>
      <p *ngIf="unacknowledgedNoticesExist" class="background-warning">Please see notices below</p>
      <table class="table table-stripe ">
        <thead>
          <tr>
            <th width="75px"></th>
            <th class="text-left">Notice</th>
            <th class="text-center">Read</th>
            <th class="text-center">Type</th>
            <th class="text-center">Reference Date</th>
            <th class="text-center">Sent On</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let notice of loginNotices">
            <td>
              <i class=" fas fa-file pointer mr-1" title="view notice" (click)="openNotice(mymodal, notice)"></i>
              <i class="text-danger pointer fas fa-ban ml-2" title="dismiss notice" (click)="dismissNotice(notice)"></i>
            </td>
            <td class="left">
              {{ notice.fbForm.name }}
            </td>
            <td class="text-center" [ngClass]="getAcknowledgedColor(notice.acknowledgedDate)">
              {{ notice.acknowledgedDate != null ? "Yes" : "No" }}
            </td>
            <td class="text-center">
              {{ notice.noticeType }}
            </td>
            <td class="text-center">
              {{ notice.referenceType }}
            </td>
            <td class="text-center">
              {{ notice.createdDate | date }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="w-100 d-flex justify-content-center" *ngIf="loginNotices == null || loginNotices.length <= 0">
        <p class="p-0 m-0">No Notices To Display</p>
      </div>
    </div>
  </div>
</div>
<!-- Notice View Modal -->
<ng-template #mymodal let-modal class="modal-lg rounded">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Notice</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeNoticeModal()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body" [style.height]="getHeightForPDF()">
    <div *ngIf="loadingDoc" class="button-loading" style="width: 50px; height: 50px"></div>
    <ng2-pdfjs-viewer #pdfViewer *ngIf="!loadingDoc && url" [pdfSrc]="url"
    [print]="true"
    [download]="false"
    [fullScreen]="false"
    [viewBookmark]="false"
    [openFile]="false"
    ></ng2-pdfjs-viewer>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" [class.button-loading]="loading" [disabled]="loading"
      (click)="closeNoticeModal()">Close</button>
  </div>
</ng-template>
<!-- End of Notice Modal -->