
<div class="row"  [ngClass]="{ 'hide': message==null, 'show': message!= null }" style="">
  <div class="col-sm-6 offset-sm-3">
      <div *ngIf="message" [ngClass]="{ 'alert': message, 'alert-success': message.type === 'success', 'alert-danger': message.type === 'error', 'alert-info': message.type === 'info' }">{{message.text}}</div>
  </div>
</div>
<p-toast position="top-center"></p-toast>

<p-toast position="top-center" key="confirmToat" (onClose)="onReject()" [modal]="true" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div style="text-align: center">
      <i class="pi pi-exclamation-triangle text-danger" style="font-size: 3em"></i>
      <h3>{{message.summary}}</h3>
      <p>{{message.detail}}</p>
    </div>
    <div class="row">
      <div class="col">
        <button type="button" pButton (click)="onConfirm()" class="btn btn-block btn-primary">Yes</button>

      </div>
      <div class="col">

        <button type="button" pButton (click)="onReject()" class="btn btn-block btn-secondary">No</button>
      </div>
    </div>
  </ng-template>
</p-toast>
