<div *ngIf="creditCardPaymentEnabled">
  <div *ngIf="isLoading">
    Loading...
  </div>
  <div *ngIf="!paymentPlan && !isLoading">
    <H2>Payment Plans</H2>
    <p>It Looks Like you are not currently enrolled in a Payment Plan.</p>
    <p>If you are in need of one please reach out to (801)255-5131 (ext. 106)</p>
    <p>and they will let you know if you qualify and answer any questions you may have.</p>
  </div>
  <div *ngIf="paymentPlan">
    <h2>Payment Plan Details</h2>
  </div>
    <div class="row">
    <div class="col-6" *ngFor="let plan of paymentPlan">
      <div class="card">
        <div class="card-body" >
          <div class="row">
            <div class="col-6">
              <h4>Payment Plan - {{ plan.ppNumber }} </h4>
            </div>
            <div class="col-sm-6">
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              Start Date:
            </div>
            <div class="col-sm-6">
              {{ plan.startDate | date: 'MM-dd-yyyy' }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              End Date:
            </div>
            <div class="col-sm-6">
              {{ plan.endDate | date: 'MM-dd-yyyy' }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              Payment Day:
            </div>
            <div class="col-sm-6">
              {{ plan.paymentDay}}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              Next Payment:
            </div>
            <div class="col-sm-6">
              {{ plan.nextPaymentDate | date: 'MM-dd-yyyy' }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              Payment Amount:
            </div>
            <div class="col-sm-6">
              ${{ plan.paymentAmount | number : '1.2-2' }}
            </div>
          </div>
          <div class="row" *ngIf="plan.totalAmount">
            <div class="col-6">
              Total Amount:
            </div>
            <div class="col-sm-6">
              ${{ plan.totalAmount | number : '1.2-2' }}
            </div>
          </div>
          <div class="row" *ngIf="!plan.totalAmount">
            <div class="col-6">
              Total Amount:
            </div>
            <div class="col-sm-6">
              ${{ plan.paymentAmount | number : '1.2-2' }}
            </div>
          </div>
          <div class="row" *ngIf="plan.lastPaymentErrorDate != null">
            <div class="col-6">
              Payment Error:
            </div>
            <div class="col-sm-6">
              {{ plan.lastPaymentErrorDate | date: 'MM-dd-yyyy' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


