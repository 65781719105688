import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AppConfig } from '../config';
import { map, tap, catchError } from 'rxjs/operators';
import { Utils } from '../helpers/utils';
import { MessageService } from 'primeng/api';

import { PatientIntakeDocumentionModel, InsuranceProviderModel, PatientInsuranceModel, PatientInsuranceCardModel} from '../../models/patient';
import { Subject, Observable, throwError, never } from 'rxjs';
import { ClientInsEditRequestModel } from '../../models/patient/clientInsuranceEditRequestModel';
import { PatientPermissionsModel } from '../../models/patient/patientPermissionsModel';
import { RemoveChangeRequestModel } from '../../models/patient/removeChangeRequestModel';

@Injectable()
export class PatientIntakeService {

  currentInquiryID: number;
  currentIntake: PatientIntakeDocumentionModel;


  constructor(private http: HttpClient,
    private messageService: MessageService) { }

  setCurrentInquiryID(id: number){
    this.currentInquiryID = id;
  }

  getPatientIntake(inquiryID: number): Observable<PatientIntakeDocumentionModel> {
    var headers = new HttpHeaders({
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    return this.http.get<PatientIntakeDocumentionModel>(
      `${AppConfig.settings.apiServer.webApiURL}/PatientIntake/${inquiryID}`,
      {
        headers
      })
      .pipe(tap(intake => {
        if (this.currentInquiryID == inquiryID)
          this.currentIntake = {
            ...this.currentIntake,
            ...intake

          };
        else
          this.currentIntake = intake;
        this.currentInquiryID = inquiryID;

        return intake;
      },
        error => {
          if (this.currentInquiryID != inquiryID)
            this.currentIntake = null;
          console.log(error);
          this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
        }

      ));
  }

  editInsuranceRequest(edit: ClientInsEditRequestModel, cardFront?: File, cardBack?: File){
    let formData = new FormData();

    formData.append('files.Index', '0');
    formData.append('files[0].File', cardFront);
    for (const key in edit) {
      if (key == "insuranceCards"){
        let insCards = edit[key];
        let index = 0
        for (const card of insCards)
        {
          for (const k in card){
            formData.append('files[0].' + key + "["+ index + "]." + k, card[k]);
          }
          index++;
        }
      }
      else if (key == "expirationDate"){
        if (edit[key] == null){
          formData.append('files[0].' + key, "");
        }
        else {
          formData.append('files[0].' + key, edit[key].toString());
        }
      }
      else{
        formData.append('files[0].' + key, edit[key]);
      }
    }
    formData.append('files.Index', '1');
    formData.append('files[1].File', cardBack);
    for (const key in edit) {
      if (key == "insuranceCards"){
        let insCards = edit[key];
        let index = 0
        for (const card of insCards)
        {
          for (const k in card){
            formData.append('files[1].' + key + "["+ index + "]." + k, card[k]);
          }
          index++;
        }
      }
      else if (key == "expirationDate"){
        if (edit[key] == null){
          formData.append('files[1].' + key, "");
        }
        else {
          formData.append('files[1].' + key, edit[key].toString());
        }
      }
      else{
        formData.append('files[1].' + key, edit[key]);
      }
    }
    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');

    return this.http.post<any>(`${AppConfig.settings.apiServer.webApiURL}/PatientIntake/ClientInsuranceEditRequest`, formData, options);
  }


  saveEditInsuranceRequest(edit: ClientInsEditRequestModel, cardFront?: File, cardBack?: File){
    let formData = new FormData();

    formData.append('files.Index', '0');
    formData.append('files[0].File', cardFront);
    for (const key in edit) {
      if (key == "insuranceCards"){
        let insCards = edit[key];
        let index = 0
        for (const card of insCards)
        {
          for (const k in card){
            formData.append('files[0].' + key + "["+ index + "]." + k, card[k]);
          }
          index++;
        }
      }
      else if (key == "expirationDate"){
        if (edit[key] == null){
          formData.append('files[0].' + key, "");
        }
        else {
          formData.append('files[0].' + key, edit[key].toString());
        }
      }
      else{
        formData.append('files[0].' + key, edit[key]);
      }
    }
    formData.append('files.Index', '1');
    formData.append('files[1].File', cardBack);

    for (const key in edit) {
      if (key == "insuranceCards"){
        let insCards = edit[key];
        let index = 0
        for (const card of insCards)
        {
          for (const k in card){
            formData.append('files[1].' + key + "["+ index + "]." + k, card[k]);
          }
          index++;
        }
      }
      else if (key == "expirationDate"){
        if (edit[key] == null){
          formData.append('files[1].' + key, "");
        }
        else {
          formData.append('files[1].' + key, edit[key].toString());
        }
      }
      else{
        formData.append('files[1].' + key, edit[key]);
      }
    }
    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');

    return this.http.post<string>(`${AppConfig.settings.apiServer.webApiURL}/PatientIntake/SaveClientInsuranceEditRequest`, formData, options);
  }


  cancelRequest(id: number, cancelReason: string, expirationDate) {
    return this.http.post<string>(`${AppConfig.settings.apiServer.webApiURL}/PatientIntake/CancelRequest`, {id: id, cancelReason: cancelReason, expirationDate: expirationDate});
  }

  removePendingInsurance(id: number){
    return this.http.delete<string>(`${AppConfig.settings.apiServer.webApiURL}/PatientIntake/RemovePendingInsurance/${id}`)
  }

  getEditInsurance(id: number){
    return this.http.get<ClientInsEditRequestModel>(`${AppConfig.settings.apiServer.webApiURL}/PatientIntake/GetEditInsurance/${id}`)
  }


  EditPendingInsuranceWithFile(edit: ClientInsEditRequestModel, cards?: PatientInsuranceCardModel[]){
    let formData = new FormData();
    let i = 0;

    for (const card of cards){
      formData.append('files.Index', i.toString());
      formData.append(`files[${i}].File`, card.file);
      for (const key in edit) {
          formData.append(`files[${i}].` + key, edit[key]);
      }
      i++;
    }
    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');

    return this.http.put<string>(`${AppConfig.settings.apiServer.webApiURL}/PatientIntake/EditPendingInsuranceWithFile`, formData, options);
  }

  EditPendingInsurance(edit: ClientInsEditRequestModel){
    return this.http.post<string>(`${AppConfig.settings.apiServer.webApiURL}/PatientIntake/EditPendingInsurance`, edit );
  }

  removeRequest(request: RemoveChangeRequestModel){
    return this.http.post<any>(`${AppConfig.settings.apiServer.webApiURL}/PatientIntake/RemoveChangeRequest`, request);
  }

  AddInsuranceRequest(edit: ClientInsEditRequestModel, cardFront?: File, cardBack?: File){
    let formData = new FormData();

    formData.append('files.Index', '0');
    formData.append('files[0].File', cardFront);
    for (const key in edit) {
      if (key == "expirationDate" || key == "startDate"  || key == "policyHolderDoB" ) {
        if (edit[key] == null){
          formData.append('files[0].' + key, "");
        }
        else {
          formData.append('files[0].' + key, edit[key].toString());
        }
      }
      else{
        formData.append('files[0].' + key, edit[key]);
      }
    }
    formData.append('files.Index', '1');
    formData.append('files[1].File', cardBack);
    for (const key in edit) {
      if (key == "expirationDate" || key == "startDate"  || key == "policyHolderDoB" ) {
        if (edit[key] == null){
          formData.append('files[1].' + key, "");
        }
        else {
          formData.append('files[1].' + key, edit[key].toString());
        }
      }
      else{
        formData.append('files[1].' + key, edit[key]);
      }
    }
    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');

    return this.http.post<number>(`${AppConfig.settings.apiServer.webApiURL}/PatientIntake/AddInsuranceRequest`, formData, options);
  }

  setDeclineMessageViewed(inquiryID: number){
    return this.http.get(`${AppConfig.settings.apiServer.webApiURL}/PatientIntake/setDeclineMessageViewed/` + inquiryID);
  }

  setPatientIntake(inquiryData: PatientIntakeDocumentionModel) {
    var data: PatientIntakeDocumentionModel = {
      ...this.currentIntake,
      ...inquiryData
    };

    var patientInsurances = data.patientInsurances;

    data.patientInsurances = [];


    // clear saved files base64 data
    if (patientInsurances != null) {
      patientInsurances.forEach(item => {


        if (item.id == null && item.insuranceProviderID == null && item.insuranceCards == null)
          return;
        var insuranceCards = item.insuranceCards;

        var patientInsurance: PatientInsuranceModel = {
          ...item,
          ...{ insuranceCards: [] }
        };
        if (insuranceCards != null) {
          insuranceCards.forEach(subItem => {
            if (subItem.id) {
              patientInsurance.insuranceCards.push({
                id: subItem.id
              });
            }

          });
        }

        data.patientInsurances.push(patientInsurance);

      });
    }

    return this.http.put<PatientIntakeDocumentionModel>(
      `${AppConfig.settings.apiServer.webApiURL}/PatientIntake/${this.currentInquiryID}`,
      data
    ).pipe(
      tap(
        data => {
          this.currentIntake = {
            ...this.currentIntake,
            ...inquiryData
          };
        },
        error => {
          this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
        }
      )
    );
  }



  setPatientInsurance(inquiryData: PatientIntakeDocumentionModel) {
    var data: PatientIntakeDocumentionModel = {
      ...this.currentIntake,
      ...inquiryData
    };

    var patientInsurances = data.patientInsurances;

    data.patientInsurances = [];


    // clear saved files base64 data
    if (patientInsurances != null) {
      patientInsurances.forEach(item => {


        if (item.id == null && item.insuranceProviderID == null && item.insuranceCards == null)
          return;
        var insuranceCards = item.insuranceCards;

        var patientInsurance: PatientInsuranceModel = {
          ...item,
          ...{ insuranceCards: [] }
        };
        if (insuranceCards != null) {
          insuranceCards.forEach(subItem => {
            if (subItem.id) {
              patientInsurance.insuranceCards.push({
                id: subItem.id
              });
            }

          });
        }

        data.patientInsurances.push(patientInsurance);

      });
    }

    return this.http.put<PatientIntakeDocumentionModel>(
      `${AppConfig.settings.apiServer.webApiURL}/PatientIntake/SetPatientInsurance/${this.currentInquiryID}`,
      data
    ).pipe(
      tap(
        data => {
          this.currentIntake = {
            ...this.currentIntake,
            ...inquiryData
          };
        },
        error => {
          this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
        }
      )
    );
  }





  parseArrayBufferToString(buf) {
    return String.fromCharCode.apply(null, new Uint8Array(buf));
  }

  setPatientPermissions(inquiryData: PatientPermissionsModel) {

    return this.http.put<PatientIntakeDocumentionModel>(
      `${AppConfig.settings.apiServer.webApiURL}/PatientIntake/${this.currentInquiryID}/Permissions`,
      inquiryData
    ).pipe(
      tap(
        data => {
          this.currentIntake = {
            ...this.currentIntake,
            ...inquiryData
          };
        },
        error => {
          console.log(error);
          this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
        }
      )
    );
  }

  setPatientInsurancesReviewed() {

    return this.http.put(
      `${AppConfig.settings.apiServer.webApiURL}/PatientIntake/${this.currentInquiryID}/InsurancesReviewed`, null
    ).pipe(
      tap(
        data => {
          this.currentIntake = {
            ...this.currentIntake,
            ...{
              insuranceReviewPending: false
            }
          };
        },
        error => {
          console.log(error);
          this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
        }
      )
    );
  }

}
