
<form [formGroup]="resetForm" (ngSubmit)="onResetSubmit()">

  <div class="card">
    <div class="card-header font-weight-bold lead bg-success text-white">
      Set Password
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-6">
          <div class="form-group">
            <label for="password">New Password</label>
            <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': resetSubmitted && f2.password.errors }" autocomplete="new-password" />
            <div *ngIf="resetSubmitted && f2.password.errors" class="invalid-feedback">
              <div *ngIf="f2.password.errors.required">New Password is required</div>
              <div *ngIf="f2.password.errors.minlength">New Password should be at least 8 characters</div>
              <div *ngIf="f2.password.errors.maxlength">New Password should be less than 255 characters</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6">
          <div class="form-group">
            <label for="confirmPassword">Confirm Password</label>
            <input type="password" class="form-control" id="confirmPassword" formControlName="confirmPassword"
                   [ngClass]="{ 'is-invalid': resetSubmitted && f2.confirmPassword.errors }" autocomplete="new-password" />
            <div *ngIf="resetSubmitted && f2.confirmPassword.errors" class="invalid-feedback">
              <div *ngIf="f2.confirmPassword.errors.required">Confirm Password is required</div>
              <div *ngIf="f2.confirmPassword.errors.confirmedValidator">New Password and Confirm Password must be match.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-12 form-group">
          <button type="submit" [disabled]="resetLoading" class="btn btn-primary btn-block btn-lg" [class.button-loading]="resetLoading">Change</button>
        </div>
      </div>
    </div>
  </div>
</form>
