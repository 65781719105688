import { CommonModule } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { AppConfig } from './config';
import {
  AlertService, AccountService,
  PatientsService,
  PatientIntakeService,
  PatientDocumentsService,
  PatientInsurancesService,
  PatientScheduleService,
  SignedDocumentsService,
  PatientStatementService,
  PatientInvoiceService,
  ClientCreditCardService} from './services';
import { AuthGuard } from './guards';
import { JwtInterceptor, ErrorInterceptor, ClientInterceptor } from './helpers';
import { FormBuilderService } from './services/formBuilder.service';
import { ClientMessagingService } from './services/clientMessaging.service';


function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
  }
}

const guards = [
  AuthGuard
];

const services = [
  AlertService,
  MessageService,
  AccountService,
  PatientsService,
  PatientIntakeService,
  PatientDocumentsService,
  PatientInsurancesService,
  PatientScheduleService,
  SignedDocumentsService,
  PatientStatementService,
  PatientInvoiceService,
  ClientCreditCardService,
  ClientMessagingService,
  FormBuilderService,
];

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule
  ],
  exports: [
    CommonModule,
    HttpClientModule,
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: JwtInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ClientInterceptor,
          multi: true
        },
        Title,
        AppConfig,
        ...guards,
        ...services
      ]
    };
  }
}
