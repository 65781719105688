<h2>Add patient</h2>

<div class="card">
  <div class="card-body">
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">

      <div class="form-group">
        <label for="patientFirstName">Patient's Legal First Name</label>
        <input type="text" formControlName="patientFirstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.patientFirstName.errors }" />
        <div *ngIf="submitted && f.patientFirstName.errors" class="invalid-feedback">
          <div *ngIf="f.patientFirstName.errors.required">Patient's First Name is required</div>
        </div>
      </div>
      <div class="form-group">
        <label for="patientLastName">Patient's Legal Last Name</label>
        <input type="text" formControlName="patientLastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.patientLastName.errors }" />
        <div *ngIf="submitted && f.patientLastName.errors" class="invalid-feedback">
          <div *ngIf="f.patientLastName.errors.required">Patient's Last Name is required</div>
        </div>
      </div>
      <div class="form-group">
        <label for="patientPreferredName">Patient's Preferred Name</label>
        <input type="text" formControlName="patientPreferredName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.patientPreferredName.errors }" />
        <div *ngIf="submitted && f.patientPreferredName.errors" class="invalid-feedback">
          <div *ngIf="f.patientPreferredName.errors.valid">Preferred Name should be less than 255 characters</div>
        </div>
      </div>
      <div class="form-group">
        <label for="patientBirthdate">Patient's Date of Birth</label>
        <input type="date" formControlName="patientBirthdate" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.patientBirthdate.errors }" />
        <div *ngIf="submitted && f.patientBirthdate.errors" class="invalid-feedback">
          <div *ngIf="f.patientBirthdate.errors.required">Patient's Date of Birth is required</div>
          <div *ngIf="f.patientBirthdate.errors.invalidDate">Patient's Date of Birth should be valid date</div>
        </div>
      </div>
      <div class="form-group">
        <label for="patientRelation">Relation to Patient</label>
        <input type="text" formControlName="patientRelation" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.patientRelation.errors }" />
        <div *ngIf="submitted && f.patientRelation.errors" class="invalid-feedback">
          <div *ngIf="f.patientRelation.errors.required">Relation to Patient is required</div>
        </div>
      </div>

      <div class="form-group">
        <label for="newPatientTrue">Patient</label><br>
        <div class="form-check-inline">
          <label class="form-check-label" for="newPatientTrue">
            <input type="radio" formControlName="newPatient" id="newPatientTrue" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.newPatient.errors }" autocomplete="off"  [value]=true /> New
          </label>
        </div>
        <div class="form-check-inline">
          <label class="form-check-label" for="newPatientFalse">
            <input type="radio" formControlName="newPatient" id="newPatientFalse" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.newPatient.errors }" autocomplete="off" [value]=false /> Existing
          </label>
        </div>
      <div *ngIf="submitted && f.newPatient.errors" class="invalid-feedback">
        </div>
      </div>
      <div class="form-group">
        <button [disabled]="loading" class="btn btn-primary">Register</button>
        <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        <a [routerLink]="['/']" class="btn btn-link">Cancel</a>
      </div>
    </form>
  </div>
</div>
