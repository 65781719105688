import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AppConfig } from '../config';
import { map, tap } from 'rxjs/operators';
import { Utils } from '../helpers/utils';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';

import { PatientInvoiceModel } from '../../models/client';
import { Subject, Observable } from 'rxjs';
import { FinancingOptionsModel } from '../../models/client/financingOptionsModel';

@Injectable()
export class PatientInvoiceService {


   
  PatientInvoices: PatientInvoiceModel[];
  $PatientInvoices = new Subject<PatientInvoiceModel[]>();


  constructor(private http: HttpClient,
    private messageService: MessageService) { }


  public getPatientInvoices(openOnly?: boolean, startDate?: Date, endDate?: Date): Observable<PatientInvoiceModel[]> {

    let params = new HttpParams();
    if (openOnly !== null && openOnly !== undefined)
      params = params.set("openOnly", openOnly.toString());
    if (startDate !==null)
      params = params.set("startDate", moment(startDate).format('YYYY-MM-DD')); 
    if (endDate !==null)
      params = params.set("endDate", moment(endDate).format('YYYY-MM-DD')); 

    return this.http.get<PatientInvoiceModel[]>(`${AppConfig.settings.apiServer.webApiURL}/PatientInvoice`, { params: params } )
      .pipe(tap(PatientInvoices => {
        if (PatientInvoices) {
          console.debug(PatientInvoices.length + ' patient invoices loaded');
          this.PatientInvoices = PatientInvoices;
          this.$PatientInvoices.next(PatientInvoices);
        }
        return PatientInvoices;
      },
        error => {
          console.log(error);
          this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
        }));

  }
  public getFinancingMessages(){

    return this.http.get<FinancingOptionsModel>(`${AppConfig.settings.apiServer.webApiURL}/PatientInvoice/GetFinanceOptions`)
      .pipe(tap(settings => {
        return settings;
      },
        error => {
          console.log(error);
          this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
        }));

  }

   /**
     * Download the patient invoice
     * 
     * @param patientInvoiceID PatientInvoiceID
     */
  getPatientInvoicePDF(patientInvoiceID?: number) {
    
    let params = new HttpParams().set("patientInvoiceID", patientInvoiceID.toString());
    
    return this.http.get(`${AppConfig.settings.apiServer.webApiURL}/PatientInvoice/Download` , 
      {
        params: params,
        responseType: 'arraybuffer',
        observe: 'response' 
      });
  }
}
