import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, AccountService} from '../../../core';

@Component({ templateUrl: 'password-reset.component.html'})
export class PasswordResetComponent implements OnInit {
    requestForm: FormGroup;
    requestLoading = false;
    requestSubmitted = false;



    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private alertService: AlertService) { }

    ngOnInit() {
      this.requestForm = this.formBuilder.group({
            username: ['', Validators.required]
        });


        // reset login status
      this.accountService.logout();


    }

    // convenience getter for easy access to form fields
    get f() { return this.requestForm.controls; }

    onRequestSubmit() {
      this.requestSubmitted = true;

        // stop here if form is invalid
      if (this.requestForm.invalid) {
            return;
      }


      this.requestLoading = true;
      this.accountService.requestpasswordreset(this.f.username.value)
            .pipe(first())
            .subscribe(
            data => {
                this.alertService.success('Request successful. Please check your email.', true);
                this.router.navigate(['/login']);
                },
                error => {
                    this.alertService.error(error);
                  this.requestLoading = false;
                });
  }

}
