import { DocumentToSignHistory } from './../../../models/DocumentToSign/DocumentToSignHistory';
import { SignedDocumentsService } from './../../../core/services/signedDocuments.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-check-signed-document',
  templateUrl: './check-signed-document.component.html',
  styleUrls: ['./check-signed-document.component.css']
})
export class CheckSignedDocumentComponent implements OnInit {

  public history: DocumentToSignHistory[];
  constructor(private signedDocumentsService: SignedDocumentsService,
    private route: ActivatedRoute, ) {
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        return this.signedDocumentsService.getSignedDocumentHistory(+params.get('documentToSignID'));
      })
    ).subscribe(history => {
      this.history = history;
      console.log(history);
    });
  }

  ngOnInit() {
  }

}
