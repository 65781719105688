import { OnInit, Input, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AccountService } from '../../../core/services/account.service';

@Component({
  selector: 'app-session-timer',
  templateUrl: 'session-timer.component.html'
})

export class SessionTimerComponent implements OnInit {

  message: string;
  showSessionTimeoutWarning: boolean = false;
  initTime: Date;
  title: string;
  warnMessage: string;
  sessionExpiredMessage: string;
  logoutButton: string;
  keepAliveButton: string;
  logoutAfter: number;
  warnAfter: number;
  sessionHasExpired = false;
  warnTimer = null;
  logoutTimer = null;

  refreshMessageTimer = null;

  refreshTimerSeconds = 0;
  _logoutAfterWarnTotalSeconds = null;
  hideFooter: boolean = false;

  constructor(private accountService: AccountService) {
    
  }

  ngOnInit() {
    console.log("Session timer started.");
    this.initTime = new Date();
    this.warnAfter = 4;
    this.logoutAfter = 5;
    this._logoutAfterWarnTotalSeconds = ((this.logoutAfter - this.warnAfter) * 60) + 1; //61 seconds

    this.title = "Session is expiring";
    this.warnMessage = 'Your session will expire in {timer}.';
    this.sessionExpiredMessage = 'Your session has expired. Refresh the page to login again.';
    this.logoutButton = "Logout";
    this.keepAliveButton = "Stay Connected";

    this.message = null;
    this.showSessionTimeoutWarning = false;

    this.restartTimers(this);

  }



  onKeepIn() {
    this.accountService.me().subscribe();

  }
  onLogout() {
    this.accountService.logout();
    window.location.href = '/login';
  }

  _stopTimers = function (me) {
    if (!me)
      me = this;
    clearInterval(me.warnTimer);
    clearInterval(me.refreshTimer);
    me.refreshTimer = null;
    me.warnTimer = null;
  }


  restartTimers = function (me) {
    if (!me)
      me = this;

    me._stopTimers();
    
    me.lastStatus = 0;
    me.warnTimer = setInterval(function () {

      if (!me.accountService.currentUser)
        return;

      var ss = me.secondsSinceLastServerCall(me);
      if (ss > me.lastStatus + 60) {
        me.lastStatus = ss;
      } else if (me.lastStatus > ss)
        me.lastStatus = ss;

      if (ss < me.warnAfter * 60)
        return;

      if (!me.refreshTimer) {
        me.refreshTimer = setInterval(function () {
          
          var secondsSinceCall = me.secondsSinceLastServerCall(me);

          if (secondsSinceCall != null && secondsSinceCall < me.warnAfter * 60) {
            me.restartTimers();
            me.showSessionTimeoutWarning = false;
            return;
          }
          else if (secondsSinceCall >= me.logoutAfter * 60) {
            me.doRefreshPage(me);
            return;
          }

          me.refreshTimerSeconds++;
          me.hideFooter = false;
          me.message = me.getLabelMessage(me);
          me.showSessionTimeoutWarning = true;
        }, 1000);
       
      }

    }, 10 * 1000);
  }

  getLabelMessage = function (me) {
    if (!me)
      me = this;
    var message = me.warnMessage;

    var secondsToExpire = (me.logoutAfter * 60) - me.secondsSinceLastServerCall(me);

    if (message.indexOf("{timer}") >= 0) {
      var time = secondsToExpire;
      var stringTime = time.toString();
      if (time > 60) {
        stringTime = `${Math.trunc(time / 60)} min ${(time % 60).toFixed()} seconds`;
      }
      else {
        stringTime = `${time.toFixed()} seconds`;
      }
      message = message.replace("{timer}", `${stringTime}`);
    }
    return message;
  }


  secondsSinceLastServerCall = function (me) {
    if (!me)
      me = this;
    if (window.localStorage) {
      var lastServerCall = window.localStorage.getItem("ClientPortalLastServerCall");

      if (lastServerCall) {
        var lastServerCallDate = new Date(lastServerCall);
        var seconds = (new Date().getTime() - lastServerCallDate.getTime()) / 1000;

        return seconds;

      }

      return me.initTime;
    }
  }

  doRefreshPage = function (me) {
    if (!me)
      me = this;

    me._stopTimers();
    me.hideFooter = true;//.hide();
    me.message = "You are being redirected...";
    
    setTimeout(function () {
      me.accountService.logout();
      window.location.href = '/login';
    }, 10 * 1000);
  }
}
