import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurePipe } from './secure.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SecurePipe],
  exports: [
    SecurePipe
  ]
})
export class SecurePipeModuleModule { }
