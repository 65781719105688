import { Component, OnInit } from '@angular/core';
import { PatientsService, AlertService } from '../../../../core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first, window, retry } from 'rxjs/operators';
import { DateValidator } from '../../../../core/helpers/date.validator';
import { CalendarModule } from 'primeng/calendar';

@Component({
  selector: 'app-patient-add',
  templateUrl: './patient-add.component.html',
  styleUrls: ['./patient-add.component.scss']
})
export class PatientAddComponent implements OnInit {

  registerForm: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    public _clientSvc: PatientsService
  ) {}

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      patientFirstName: ['', Validators.required],
      patientLastName: ['', Validators.required],
      patientPreferredName: ['', Validators.maxLength(255)],
      patientBirthdate: ['', [Validators.required, DateValidator()]],
      patientRelation: ['', Validators.required],
      newPatient: [true, Validators.required]

    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    
    this.loading = true;
    this._clientSvc.addPatient(this.registerForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.alertService.success('Registration successful.', true);
          this.router.navigate(['/patient/' + data.inquiryID]);
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        });
  }

}
