<div class='row navbar-fixed-top'>
  <div class='col-3 col-md-2 align-middle'>
    <a class="navbar-brand" [routerLink]='["/"]'>
      <img  [src]="generalSettings?.businessLogoUrl || '/assets/img/OPTO Logo.png'" height="50" [alt]="generalSettings?.businessFullName">
    </a>
  </div>
  <div class='col-md-5 text-center align-bottom d-none  d-md-block' style="margin-top: 15px" >
    <span *ngIf="loggedUser && loggedUser.impersonatedByUser">Viewed as by {{loggedUser.impersonatedByUser.name}}
    </span>
    <div *ngIf="loggedUser!==null" class=" d-none ">
      <a [routerLink]="['/']" class="btn  btn-link" style="color: inherit">
        <i class="material-icons">search</i>
      </a>
      <input type="text" class="searchBox" placeholder="Type your search" />
    </div>
  </div>
  <div class='col-9  col-md-5 text-right align-bottom' style="margin-top: 15px">
    <div *ngFor="let session of doxyMeSessions" style="display: contents">
      <button class="btn btn-primary"  [disabled]="loading" (click)="openDoxyMe(session)" *ngIf="loggedUser!==null">Doxy.me {{session.startTime | date:'shortTime'}} - {{session.clinicianFirstName}} {{session.clinicianLastName}}</button>
    </div>
    <a (click)="menu.toggle($event)" class="btn btn-link " *ngIf="loggedUser!==null" id="mainMenu">
      <i class="material-icons">menu</i>
    </a>
  </div>
</div>
<p-menu #menu [popup]="true" [model]="items"></p-menu>
