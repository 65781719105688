<h1>Document History</h1>
    <div class="col-12" *ngIf="history">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title"></h5>
            <table class="table table-stripe ">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>
                    Action
                  </th>
                  <th>
                    Name
                  </th>
                  <th>
                    Email
                  </th>
                  <th>
                    Logged in on
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let action of history">
                  <td>
                    {{action.date | date:'shortDate'}} {{action.date | date:'shortTime'}}
                  </td>
                  <td>
                      {{action.action}}
                  </td>
                  <td>
                    {{action.participant.name}}
                  </td>
                  <td>
                    {{action.participant.email}}
                  </td>
                  <td>
                    {{action.participant.userLoginSystem}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
