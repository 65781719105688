import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { MessageService } from 'primeng/api';
import { retry } from 'rxjs/operators';

@Injectable()
export class AlertService {
  private subject = new Subject<any>();
  private keepAfterNavigationChange = false;

  $confirm = new Subject<boolean>();


  constructor(private router: Router,
    private messageService: MessageService) {

    // clear alert message on route change
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next();
        }
      }
    });
  }
  // https://www.primefaces.org/primeng/#/toast

  success(message: string, keepAfterNavigationChange = false) {
    if (keepAfterNavigationChange) {
      this.keepAfterNavigationChange = keepAfterNavigationChange;
      this.subject.next({ type: 'success', text: message });
    } else {
      this.messageService.add({ severity: 'success', detail: message });
    }
  }

  info(message: string, keepAfterNavigationChange = false) {
    if (keepAfterNavigationChange) {
      this.keepAfterNavigationChange = keepAfterNavigationChange;
      this.subject.next({ type: 'info', text: message });
    } else {
      this.messageService.add({ severity: 'info', detail: message });
    }
  }

  error(message: string, keepAfterNavigationChange = false) {
    if (keepAfterNavigationChange) {
      this.keepAfterNavigationChange = keepAfterNavigationChange;
      this.subject.next({ type: 'error', text: message });
    } else {
      this.messageService.add({ severity: 'error', detail: message });
    }
  }


  confirm(summary: string, detail: string, severity: string ='warn'): Observable<boolean> {
    this.messageService.add({ key: 'confirmToat', sticky: true, severity: severity, summary: summary, detail: detail });

    this.$confirm = new Subject<boolean>();
    return this.$confirm;
    
  }


  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
