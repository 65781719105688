import { FBFormModel } from './../../models/formBuilder/FormBuilderModels';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AppConfig } from '../config';
import { map, tap, catchError } from 'rxjs/operators';
import { Utils } from '../helpers/utils';
import { MessageService } from 'primeng/api';

import { PatientIntakeDocumentionModel, InsuranceProviderModel, PatientInsuranceModel } from '../../models/patient';
import { Subject, Observable, throwError, never } from 'rxjs';
import { FBFormAssignmentModel, FBFormSubmissionModel } from '../../models/formBuilder/FormBuilderModels';

@Injectable()
export class FormBuilderService {
  public availableForms: FBFormAssignmentModel[];
  public unAcknowledgedNotices: FBFormAssignmentModel[];
  constructor(private http: HttpClient,
    private messageService: MessageService) { }

  getAvailableForms(inquiryID: number): Observable<FBFormAssignmentModel[]> {
    return this.http.get<FBFormAssignmentModel[]>(
      `${AppConfig.settings.apiServer.webApiURL}/FormBuilder/AvailableForms/${inquiryID}`)
      .pipe(tap(availableForms => {
        availableForms = availableForms;
      },
        error => {
          console.log(error);
          this.messageService.add({ severity: 'error', detail: 'Error: ' + (error.message || error) });
        }

      ));
  }

  optOutFormAssignment(formAssignmentID: number): Observable<any> {
    return this.http.post<any>(`${AppConfig.settings.apiServer.webApiURL}/FormBuilder/OptOutAssignment/${formAssignmentID}`,{});
  }

  getFormSubmission(formID: number, inquiryID: number, formSubmissionID?: number) {
    if (formSubmissionID > 0)
      return this.http.get<FBFormSubmissionModel>(`${AppConfig.settings.apiServer.webApiURL}/FormBuilder/FormSubmission/${formID}/${inquiryID}/${formSubmissionID}`);
    else
      return this.http.get<FBFormSubmissionModel>(`${AppConfig.settings.apiServer.webApiURL}/FormBuilder/FormSubmission/${formID}/${inquiryID}`);
  }


  saveFormSubmissionForm(formSubmission: FBFormSubmissionModel) {
    return this.http.post<FBFormSubmissionModel>(`${AppConfig.settings.apiServer.webApiURL}/FormBuilder/FormSubmission`,
      formSubmission);
  }

  
  getLoginNotices(showAll: boolean): Observable<FBFormSubmissionModel[]> {
    return this.http.get<FBFormSubmissionModel[]>(
      `${AppConfig.settings.apiServer.webApiURL}/FormBuilder/GetLoginNotices/`+ showAll)
      .pipe(tap(unAcknowledgedNotices => {
        unAcknowledgedNotices = unAcknowledgedNotices;
      },
        error => {
          console.log(error);
          this.messageService.add({ severity: 'error', detail: 'Error: ' + (error.message || error) });
        }
      ));
  }

  dismissNotice(formSubmissionID: number) {
    var data = {
      submissionID: formSubmissionID,
    }
    return this.http.get<boolean>(`${AppConfig.settings.apiServer.webApiURL}/FormBuilder/DismissNotice/` + formSubmissionID );
  }

  uploadValueFieldFile(formSubmissionID: number, formFieldID: number, file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);

    //const options = { headers: new HttpHeaders() };
    //options.headers.append('enctype', 'multipart/form-data');

    return this.http.post<number>(`${AppConfig.settings.apiServer.webApiURL}/FormBuilder/FormAssignment/AttachFile/${formSubmissionID}/${formFieldID}`, formData);
  }

  finishFormSubmissionForm(formSubmission: FBFormSubmissionModel) {
    return this.http.post<FBFormSubmissionModel>(`${AppConfig.settings.apiServer.webApiURL}/FormBuilder/FinishFormSubmission`,
      formSubmission);
  }



  getFormSubmissionDocToSign(formSubmissionID: number): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${AppConfig.settings.apiServer.webApiURL}/FormBuilder/FormSubmissionDocumentToSign/${formSubmissionID}`,
      { responseType: 'arraybuffer' as 'json' });
  }

  getFormSubmissionNoticeDoc(formSubmissionID: number): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${AppConfig.settings.apiServer.webApiURL}/FormBuilder/FormSubmissionNoticeDocument/${formSubmissionID}`,
      { responseType: 'arraybuffer' as 'json' });
  }

  getFormSubmissionGetDoc(formSubmissionID: number): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${AppConfig.settings.apiServer.webApiURL}/FormBuilder/FormSubmissionGetDoc/${formSubmissionID}`,
      { responseType: 'arraybuffer' as 'json' });
  }

  signFormSubmissionDoc(formSubmissionID: number, signedFields: string[]): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${AppConfig.settings.apiServer.webApiURL}/FormBuilder/FormSubmissionSignDoc/${formSubmissionID}`,
      signedFields,
      { responseType: 'arraybuffer' as 'json' });
  }

  getAutoAssignableForms(): Observable<FBFormModel[]> {
    return this.http.get<FBFormModel[]>(
      `${AppConfig.settings.apiServer.webApiURL}/FormBuilder/AutoAssignableForms`)
      .pipe(tap(AutoAssignableForms => {

      },
        error => {
          console.log(error);
          this.messageService.add({ severity: 'error', detail: 'Error: ' + (error.message || error) });
        }

      ));
  }
  cancelFormSubmissionForm(formSubmissionID: number): Observable<any> {
    return this.http.get<any>(`${AppConfig.settings.apiServer.webApiURL}/FormBuilder/FormSubmissionCancel/${formSubmissionID}`);
  }

  skipForm(formSubmissionID: number): Observable<any> {
    return this.http.get<any>(`${AppConfig.settings.apiServer.webApiURL}/FormBuilder/FormAssignmentSkip/${formSubmissionID}`);
  }

}
