import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';


import { AlertService } from '../../core';

@Component({
    selector: 'app-alert',
    templateUrl: 'alert.component.html'
})

export class AlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: any;

    constructor(private alertService: AlertService, private messageService: MessageService) { }

    ngOnInit() {
        this.subscription = this.alertService.getMessage().subscribe(message => {
            this.message = message;
        });
    }

    ngOnDestroy() {
      this.subscription.unsubscribe();
    }



  onConfirm() {
    this.messageService.clear('confirmToat');

    this.alertService.$confirm.next(true);

    this.alertService.$confirm.complete();
    
    }
  onReject() {
    this.messageService.clear('confirmToat');
    this.alertService.$confirm.next(false);

    this.alertService.$confirm.complete();
    }

}
