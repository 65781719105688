import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { CalendarModule } from 'primeng/calendar';
import { DateValidator } from '../../../core/helpers/date.validator';

import { AlertService, AccountService } from '../../../core';

@Component({ templateUrl: './register.component.html'})
export class RegisterExistingComponent implements OnInit {
    registerForm: FormGroup;
  loading = false;
  today: string;
    submitted = false;
    title = 'Register existing patient';
    relations: string[] = ['Self', 'Mother', 'Father'];
    filteredRelations: any[];

    constructor(
        private formBuilder: FormBuilder,
      private router: Router,
      private accountService: AccountService,
      private alertService: AlertService) { this.today = new Date().toJSON().split('T')[0]; }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(1)]],
            phone: ['', [Validators.required, Validators.pattern('^[+]{0,1}[0-9]{0,4}[( ]{0,2}[0-9]{3,4}[-) ]{0,2}[0-9]{3,4}[-]{0,1}[0-9]{4,5}$')]],
            patientFirstName: ['', Validators.required],
            patientLastName: ['', Validators.required],
            patientPreferredName: ['', Validators.maxLength(255)],
            patientBirthdate: ['', [Validators.required, DateValidator()]],
            patientRelation: ['', Validators.required],
            newPatient: [false]

        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
      }
      this.registerForm.value.newPatient = false;


        this.loading = true;
      this.accountService.register(this.registerForm.value)
            .pipe(first())
            .subscribe(
              data => {
                this.alertService.success('Registration successful. Please verify your email address.', true);
                    this.router.navigate(['/login']);
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
  }

  filterRelations(event) {
    this.filteredRelations = [];
    for (let i = 0; i < this.relations.length; i++) {
      let relation = this.relations[i];
      if (relation.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredRelations.push(relation);
      }
    }
  }

  setSelf(event: Event) {
    this.registerForm.patchValue({
      patientRelation: 'Self',
      firstName: this.registerForm.value.patientFirstName,
      lastName: this.registerForm.value.patientLastName
    });
    event.stopPropagation();
    event.cancelBubble = true;

    return false;
  }

}
