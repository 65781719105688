import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../config';
import { map } from 'rxjs/operators';

import { Utils } from '../helpers';
import { Observable } from 'rxjs';
import { DocumentToSignHistory } from '../../models/DocumentToSign/DocumentToSignHistory';

@Injectable()
export class SignedDocumentsService {

  constructor(private http: HttpClient) {}

  getSignedDocumentHistory(documentToSignID: number): Observable<DocumentToSignHistory[]> {
    return this.http.get<DocumentToSignHistory[]>(`${AppConfig.settings.apiServer.webApiURL}/CheckSignedDocument/${documentToSignID}`);
  }

}
