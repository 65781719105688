import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfig } from '../config';
import { map, tap } from 'rxjs/operators';
import { Utils } from '../helpers/utils';
import { MessageService } from 'primeng/api';

import { PatientScheduleModel } from '../../models/patient';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class PatientScheduleService {
  
  scheduleServices: PatientScheduleModel[];
  $scheduleServices = new Subject<PatientScheduleModel[]>();


  constructor(private http: HttpClient,
    private messageService: MessageService) { }


  public getPatientSchedule(patientID?: number): Observable<PatientScheduleModel[]> {
    return this.http.get<PatientScheduleModel[]>(`${AppConfig.settings.apiServer.webApiURL}/PatientSchedule?patientID=${patientID}`)
      .pipe(tap(scheduleServices => {
        if (scheduleServices) {
          console.debug(scheduleServices.length + ' schedule services loaded');
          this.scheduleServices = scheduleServices;
          this.$scheduleServices.next(scheduleServices);
        }
        return scheduleServices;
      },
        error => {
          console.log(error);
          this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
        }));

  }

  

  public getOpenDoxyMeSession(): Observable<PatientScheduleModel[]> {
    return this.http.get<PatientScheduleModel[]>(`${AppConfig.settings.apiServer.webApiURL}/PatientSchedule/OpenDoxyMeSessions`)
      .pipe(tap(scheduleServices => {
        if (scheduleServices) {
          console.debug(scheduleServices.length + ' schedule services loaded');
          this.scheduleServices = scheduleServices;
          this.$scheduleServices.next(scheduleServices);
        }
        return scheduleServices;
      },
        error => {
          console.log(error);
          this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
        }));

  }

  getDoxyMeSession(patientHasEventID: number) {
    return this.http.get<any>(`${AppConfig.settings.apiServer.webApiURL}/PatientSchedule/OpenDoxyMeSession/${patientHasEventID}`)
      .pipe(tap(doxyMeLink => {
        if (doxyMeLink) {
          return doxyMeLink.link;
        }
        return "";
      },
        error => {
          console.error(error);
          this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
        }));
  }

    /**
     * Cancel patient schedule sessions
     * 
     * @param patientHasEventID patientHasEventID
     * @param moreInfo 
     */
  cancelPatientSchedule(patientHasEventID: number, moreInfo?: string) {

    let queryParameters = new HttpParams();
    if (moreInfo !== undefined && moreInfo !== null) {
      queryParameters = queryParameters.set('moreInfo', <any>moreInfo);
    }


    return this.http.delete(`${AppConfig.settings.apiServer.webApiURL}/PatientSchedule/` + patientHasEventID, {
      params: queryParameters
    });
  }
}
