import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CheckSignedDocumentComponent } from './check-signed-document/check-signed-document.component';


const routes: Routes = [
  { path: 'CheckSignedDocument/:documentToSignID', component: CheckSignedDocumentComponent },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class PublicRoutingModule {}

