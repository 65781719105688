import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MessageService } from 'primeng/api';

import { AlertService, AccountService } from '../../../core';

@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  checkingCode = false;
  submitted = false;
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // reset login status
    this.accountService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';

    // get return url from route parameters or default to '/'
    const code = this.route.snapshot.queryParams['code'];
    if (code != null) {

      this.checkingCode = true;
      this.accountService.authenticateToken(code)
        .pipe(first())
        .subscribe(
          data => {
            if (data.mustSetPassword) {
              this.alertService.info('Please set a new password', true);
              this.router.navigate(['/set-password'], { queryParams: { token: code } } );
            } else if (data != null && (this.isNullOrWhiteSpace(data.firstName) || this.isNullOrWhiteSpace(data.lastName) 
            || this.isNullOrWhiteSpace(data.address) || this.isNullOrWhiteSpace(data.city) || this.isNullOrWhiteSpace(data.state) 
            || this.isNullOrWhiteSpace(data.zip) || this.isNullOrWhiteSpace(data.country) )){
                this.router.navigate(['/client/settings'])
            } else {
              this.router.navigate([this.returnUrl]);
            }

          },
          error => {
            this.alertService.error(error);
            this.checkingCode = false;
          });
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }
  
  isNullOrWhiteSpace(s: any){
    if (s != null && s != ""){
      return false;
    }
    else {
      return true;
    }
  }
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }


    this.loading = true;
    this.accountService.authenticate(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
      data => {
        if (!data.emailVerified && data.email != null) {
          this.alertService.error('Your email is not verified. Please check your email.', true);
          this.accountService.logout();
          this.loading = false;
            // this.router.navigate(['/password-reset']);
        } else if (data != null && (this.isNullOrWhiteSpace(data.firstName) || this.isNullOrWhiteSpace(data.lastName) 
        || this.isNullOrWhiteSpace(data.address) || this.isNullOrWhiteSpace(data.city) || this.isNullOrWhiteSpace(data.state) 
        || this.isNullOrWhiteSpace(data.zip) || this.isNullOrWhiteSpace(data.country) )){
            this.router.navigate(['/client/settings'])
        } else {
            this.router.navigate([this.returnUrl]);
        }
        },
        error => {
          // this.alertService.error(error);
          this.loading = false;
          this.messageService.add({ severity: 'error', detail: error });
        });
  }
}
