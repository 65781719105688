import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { PaymentPlanService } from '../../../../core/services/payment-plan.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ChargePaymentPlanModel } from '../../../../models/patient/charge-payment-plan-model';
import { AccountService } from '../../../../core';
declare let ga: any;

@Component({
  selector: 'app-payment-plan',
  templateUrl: './payment-plan.component.html',
  styleUrls: ['./payment-plan.component.scss']
})
export class PaymentPlanComponent implements OnInit {
  inquiryId: number;
  closeResult: string;
  currentUser;
  paymentPlan;
  isLoading: boolean = true;
  creditCardPaymentEnabled: boolean = false;
  city: string = "";
  lastName: string = "";
  cpp: ChargePaymentPlanModel;
  constructor(    
    private route: ActivatedRoute,
    public _paymentPlanService: PaymentPlanService,
    private accountService: AccountService,
    private modalService: NgbModal) 
    {
      const currentUserJ = localStorage.getItem('currentUser');
      if (currentUserJ != null) {
        this.currentUser = JSON.parse(currentUserJ);
        ga('set', 'userId', this.currentUser.ID + '');
      } else {
        this.currentUser = null;
      }
    }
    

  ngOnInit() {
        this._paymentPlanService.getPaymentPlans(this.currentUser.id).subscribe(plan => {
          this.paymentPlan = plan;
          this.isLoading = false;
        }, error => {
          console.log('Error Loading Payment Plan.');
          console.log(error);
        });

    this.creditCardPaymentEnabled = this.accountService
      && this.accountService.currentUser
      && this.accountService.currentUser.featureSwitchIDs
      && !!this.accountService.currentUser.featureSwitchIDs.find(x => x === 82);
  }
}
