
import { Component, OnInit } from '@angular/core';
import { AccountService, PatientsService, PatientInvoiceService } from '../../core';
import { ClientMessagingService } from '../../core/services/clientMessaging.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html'
})
export class MainComponent implements OnInit {
  constructor(
    public _patientsService: PatientsService,
    public clientMessagingService: ClientMessagingService,
    public patientInvoiceService: PatientInvoiceService
  ) {}

  ngOnInit() {
    this._patientsService.getPatients().subscribe(patients => { });
    this.clientMessagingService.getReceivers().subscribe(data => { });
    //this.clientMessagingService.getMessageThreads().subscribe(messages => { });
    this.patientInvoiceService.getPatientInvoices(true).subscribe(invoices=> { });
  }
}
