import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { AppConfig } from '../config';
import { map, tap } from 'rxjs/operators';
import { Utils } from '../helpers/utils';
import { MessageService } from 'primeng/api';
import { PaymentPlanModel } from '../../models/patient/payment-plan-model';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import { MessageReceiverModel, MessageThreadModel, MessageModel, MessageAttachmentModel,  NewMessageModel, PatientAllowedMessageReceiverModel } from '../../models/messaging/';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class ClientMessagingService {

  canSendReceiveMessage: boolean;

  unreadMessages: MessageThreadModel[];
  $unreadMessages = new Subject<MessageThreadModel[]>();

  messages: MessageThreadModel[];
  $messages = new Subject<MessageThreadModel[]>();

  receivers: PatientAllowedMessageReceiverModel[];
  $receivers = new Subject<PatientAllowedMessageReceiverModel[]>();


  photos: any;

  constructor(private http: HttpClient,
    private accountService: AccountService,
    private messageService: MessageService) {
    this.canSendReceiveMessage = false;
    this.photos = [];
  }

   

    public getMessageThreads(): Observable<MessageThreadModel[]> {

      return this.http.get<MessageThreadModel[]>(`${AppConfig.settings.apiServer.webApiURL}/Messages/Thread`, {})
        .pipe(tap(messages => {
          if (messages) {
            console.debug(messages.length + ' message threads loaded');
            var orderedMessages = messages.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

            this.messages = orderedMessages;
            this.$messages.next(orderedMessages);

            this.unreadMessages = orderedMessages.filter(x => x.unread > 0);
            this.$unreadMessages.next(this.unreadMessages);
            
          }
          return messages;
        },
          error => {
            console.log(error);
            this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
          }));
  }

  public loadMessageThread(messageThreadID: number): Observable<MessageThreadModel> {

    return this.http.get<MessageThreadModel>(`${AppConfig.settings.apiServer.webApiURL}/Messages/Thread/${messageThreadID}`, {})
      .pipe(tap(messageThread => {
        if (messageThread && messageThread.messages && messageThread.messages.length>0) {
          console.debug(`${messageThreadID} message thread loaded with ${messageThread.messages.length}`);
        }
        return messageThread;
      },
        error => {
          console.log(error);
          this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
        }));
  }

    /**
     *
      Get the list of possible receivers for a new message
     */
    public getReceivers(): Observable<PatientAllowedMessageReceiverModel[]> {
      return this.http.get<PatientAllowedMessageReceiverModel[]>(`${AppConfig.settings.apiServer.webApiURL}/Messages/Receivers`, {  } )
        .pipe(tap(r => {
          if (r) {
            console.debug(r.length + ' Receivers loaded');
            this.canSendReceiveMessage = r.length>0;
            this.receivers = r;
            this.$receivers.next(r);
          }
          return r ;
        },
          error => {
            console.log(error);
            this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
          }));
    }

    /**
     *
      Send Message
     */
  public NewMessage(message: NewMessageModel) {
      
      const options = { headers: new HttpHeaders() };

    return this.http.post<number>(
        `${AppConfig.settings.apiServer.webApiURL}/Messages/New`, message, options)
      .pipe(
        tap(
          response => {
            console.debug(`NewMessage ${response}`);
          },
          error => {
            this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
          }
        )
      );
    }

    /**
     *
      Reply Message
     */
  public ReplyMessage(messageTreadID: number, message: string){
        let formData = new FormData();
        formData.append('messageTreadID', messageTreadID.toString());
        formData.append('message', message);

        const options = { headers: new HttpHeaders() };
        options.headers.append('enctype', 'multipart/form-data');

        return this.http.post<number>(
          `${AppConfig.settings.apiServer.webApiURL}/Messages/Reply`, formData, options).pipe(
          tap(
            response => {
              console.debug(`ReplyMessage ${response}`);
            },
            error => {
              this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
            }
          )
        );
      }

    /**
     *
      MarkMessageAsRead
     */
  public MarkMessageAsRead(messageID?: number, messageTreadID?: number) {
    return this.http.put<string>(
        `${AppConfig.settings.apiServer.webApiURL}/Messages/Flag?flag=read&messageID=${messageID}&messageTreadID=${messageTreadID}`,
        { }
      ).pipe(
        tap(
          response => {
            console.debug(`MarkMessageAsRead ${messageID} ${messageTreadID}`);
          },
          error => {
            this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
          }
        )
      );
    }

    /**
     *
      MarkMessageAsArchived
     */
  public MarkMessageAsArchived(messageID?: number, messageTreadID?: number) {
      return this.http.delete<string>(
        `${AppConfig.settings.apiServer.webApiURL}/Messages/Flag?flag=archive&messageID=${messageID}&messageTreadID=${messageTreadID}`,
        { }
      ).pipe(
        tap(
          response => {
            console.debug(`MarkMessageAsArchived ${messageID} ${messageTreadID}`);
          },
          error => {
            this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
          }
        )
      );
    }

    /**
     *
      Upload Attachment
     */
    public UploadAttachment(messageID: number, file: File){
      let formData = new FormData();
      formData.append('messageID', messageID.toString());
      formData.append('file', file);
      const options = { headers: new HttpHeaders() };
      options.headers.append('enctype', 'multipart/form-data');

      return this.http.post<MessageAttachmentModel>(`${AppConfig.settings.apiServer.webApiURL}/Messages/Attachement`, formData, options);
    }

    /**
     * Download message attachment
     *
     * @param documentID
     */
    public downloadAttachment(documentID: number): Observable<ArrayBuffer> {
      return this.http.get(`${AppConfig.settings.apiServer.webApiURL}/Messages/Attachement/${documentID}`,
        { responseType: 'arraybuffer'});
    }

    /**
     * Download sender profile picture
     *
     * @param photoID
     */
  public getSenderPhoto(photoID: number): Observable<ArrayBuffer> {
    if (this.photos[photoID])
      return this.photos[photoID];

      return this.http.get(`${AppConfig.settings.apiServer.webApiURL}/Messages/SenderPhoto/${photoID}`,
        { responseType: 'arraybuffer' }).pipe(
          tap(
            response => {
              // console.debug('MarkMessageAsArchived done with response: ' + response);
                this.photos[photoID] = response;
            },
            error => {
              this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
            }
          )
        );
    }

}
