import { Component, OnInit, ViewChild } from '@angular/core';
import { PatientModel, PatientScheduleModel } from '../../../models/patient';
import { LoginModel } from '../../../models/account';
import { MenuItem } from 'primeng/api';
import { AppConfig, AccountService, PatientsService, PatientScheduleService, ClientMessagingService } from '../../../core';
import { Menu } from 'primeng/menu';
import { GeneralSettingsModel } from '../../../models/account/generalSettingsModel';
import { MessageThreadModel } from '../../../models/messaging';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  items: MenuItem[];
  timeoutDoxy;
  creditCardPaymentEnabled: boolean = false;

  get loggedUser() { return this.accountService.currentUser; }
  doxyMeSessions: PatientScheduleModel[];
  loading: boolean = false;

  get generalSettings(): GeneralSettingsModel {
    return this.accountService ? this.accountService.generalSettings : null;
  }
  constructor(
    public accountService: AccountService,
    public patientsService: PatientsService,
    private patientScheduleService: PatientScheduleService,
    private clientMessagingService: ClientMessagingService,
  ) {

    patientsService.$patients.subscribe(data => {
      this.loadMenuItems();
      this.loadDoxyMeEvent();
    });

    clientMessagingService.$receivers.subscribe(data => {
      this.loadMenuItems();
    });
  }

  ngOnInit() {
    this.loadMenuItems();
  }

  loadMenuItems() {
    this.creditCardPaymentEnabled = this.accountService
      && this.accountService.currentUser
      && this.accountService.currentUser.featureSwitchIDs
      && !!this.accountService.currentUser.featureSwitchIDs.find(x => x === 82);

    var patients: Array<any> = [];

    if (this.patientsService.patients && this.patientsService.patients.length > 0 && this.patientsService.patients.length <= 10) {
      patients = [... this.patientsService.patients.map(patient => ({
        label: patient.firstName + ' ' + patient.lastName,
        icon: 'fal fa-fw fa-user',
        routerLink: [`/patient/${patient.inquiryID}`]
      }))];
    }

    
    var messagesItem = [];
    if (this.clientMessagingService.canSendReceiveMessage) {
      messagesItem = [
        
        {
          separator: true
        },{
            label: 'View Messages',
          icon: 'fal fa-fw fa-comments',
            routerLink: ['/messaging/message-list'],
        },
        {
          label: 'New Message',
          icon: 'fal fa-fw fa-comment-plus',
          routerLink: ['/messaging/message-send'],
        }      ];
    }

    var accountFirstCreditSubItem = this.creditCardPaymentEnabled ? [
    {
      label: 'Receipts',
      icon: 'fal fa-fw fa-receipt',
      routerLink: ['/client/receipt']
    },
    {
      label: 'Make Payment',
      icon: 'fal fa-fw fa-credit-card-front',
      routerLink: ['/client/payment']
    }] : [];

    var accountSecondCreditSubItem = this.creditCardPaymentEnabled ? [{
      label: 'Payment Plan',
      icon: 'fal fa-fw fas fa-money-check-alt',
      routerLink: ['/paymentplan']
    }] : [];


    this.items = [

      {
        label: 'Patients',
        items: [
          ...patients,
          {
            label: 'View Patients',
            icon: 'fal fa-fw fa-users',
            routerLink: ['/'],
            queryParams: { show: 'all' }
          },
          //{
          //  label: 'Add Patient',
          //icon: 'fal fa-fw fa-user-plus',
          //  routerLink: ['/patient/add']
          //}
          ...messagesItem,
        ]
      },
      {
        label: 'Account',
        //    icon: 'fal fa-fw fa-money-bill',
        items: [{
          label: 'Invoices',
          icon: 'fal fa-fw fa-file-invoice-dollar',
          routerLink: ['/client/invoice']
        },
        {
          label: 'Notices',
          icon: 'far fa-fw fa-bell',
          routerLink: ['/notices']
        },
        ...accountFirstCreditSubItem,
          {
            label: 'Profile / Settings',
            icon: 'fal fa-fw fa-user-cog',
            routerLink: ['/client/settings']
          },
          ...accountSecondCreditSubItem,
          {
            label: 'Logout',
            icon: 'fal fa-fw fa-sign-out',
            routerLink: ['/login']
          }
          ]
      }];


  }

  loadDoxyMeEvent() {

    if (this.loggedUser != null) {
      this.patientScheduleService.getOpenDoxyMeSession().subscribe(sessions => {
        this.doxyMeSessions = sessions;
        if (this.timeoutDoxy){
          clearTimeout(this.timeoutDoxy);
        }
        this.timeoutDoxy = setTimeout(() => { this.loadDoxyMeEvent() }, 120000);
      });
    }
  }

  openDoxyMe(session: PatientScheduleModel) {
    this.loading = true;
    this.patientScheduleService.getDoxyMeSession(session.patientHasEventID).subscribe(doxyMeLink => {
      this.loading = false;
      window.open(doxyMeLink.link, "_blank");
    }, () => {
      this.loading = false;
    });
  }
}
