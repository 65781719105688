<h2>{{title}}</h2>

<div class="card">
  <div class="card-body">
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">

      <div class="form-group">
        <label for="patientFirstName">Patient's Legal First Name</label>
        <input type="text" formControlName="patientFirstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.patientFirstName.errors }" />
        <div *ngIf="submitted && f.patientFirstName.errors" class="invalid-feedback">
          <div *ngIf="f.patientFirstName.errors.required">Patient's First Name is required</div>
        </div>
      </div>
      <div class="form-group">
        <label for="patientLastName">Patient's Legal Last Name</label>
        <input type="text" formControlName="patientLastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.patientLastName.errors }" />
        <div *ngIf="submitted && f.patientLastName.errors" class="invalid-feedback">
          <div *ngIf="f.patientLastName.errors.required">Patient's Last Name is required</div>
        </div>
      </div>
      <div class="form-group">
        <label for="patientPreferredName">Patient's Preferred Name</label>
        <input type="text" class="form-control" id="preferredName" formControlName="patientPreferredName">
        <div *ngIf="submitted && f.patientPreferredName.errors" class="invalid-feedback">
          <div *ngIf="f.patientPreferredName.errors.valid">Preferred Name should be less than 255 characters</div>
        </div>
      </div>
      <div class="form-group">
        <label for="patientBirthdate">Patient's Date of Birth</label>
        <input type="date" formControlName="patientBirthdate" class="form-control" max="{{today}}" [ngClass]="{ 'is-invalid': submitted && f.patientBirthdate.errors }" />
        <div *ngIf="submitted && f.patientBirthdate.errors" class="invalid-feedback">
          <div *ngIf="f.patientBirthdate.errors.required">Patient's Date of Birth is required</div>
          <div *ngIf="f.patientBirthdate.errors.invalidDate">Patient's Date of Birth should be valid date</div>
        </div>
      </div>
      <div class="form-group">
        <label for="patientRelation">Relation to Patient <a href="#" (click)="setSelf($event)">(self?)</a></label>
        <p-autoComplete formControlName="patientRelation" [suggestions]="filteredRelations" (completeMethod)="filterRelations($event)" [size]="30"
                        [minLength]="1" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%', 'border-color':'rgb(206, 212, 218)'}" [inputStyleClass]="'form-control'" [ngClass]="{ 'is-invalid': submitted && f.patientRelation.errors }">
        </p-autoComplete>
        <div *ngIf="submitted && f.patientRelation.errors" class="invalid-feedback">
          <div *ngIf="f.patientRelation.errors.required">Relation to Patient is required</div>
        </div>
      </div>

      <div class="form-group">
        <label for="firstName">First Name</label>
        <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
          <div *ngIf="f.firstName.errors.required">First Name is required</div>
        </div>
      </div>
      <div class="form-group">
        <label for="lastName">Last Name</label>
        <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
          <div *ngIf="f.lastName.errors.required">Last Name is required</div>
        </div>
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">Email is required</div>
          <div *ngIf="f.email.errors.email">Email should be valid.</div>
        </div>
      </div>
      <div class="form-group">
        <label for="phone">Phone</label>
        <input type="tel" formControlName="phone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" placeholder="123-456-7890" pattern="^[+]{0,1}[0-9]{0,4}[( ]{0,1}[0-9]{3,4}[-) ]{0,1}[0-9]{3,4}[-]{0,1}[0-9]{4,5}$" />
        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
          <div *ngIf="f.phone.errors.required">Phone is required</div>
          <div *ngIf="f.phone.errors.pattern">Phone should be valid phone format. i.e. 123-456-7890</div>
        </div>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" autocomplete="off" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Password is required</div>
          <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
        </div>
      </div>
      <div class="form-group">
        <button [disabled]="loading" class="btn btn-primary">Register</button>
        <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        <a [routerLink]="['/login']" class="btn btn-link">Cancel</a>
      </div>
    </form>

  </div>
</div>
