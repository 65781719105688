import { Injectable } from '@angular/core';
import { HttpClient,  } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { IAppConfig } from '../../models/app-config.model';

@Injectable()
export class AppConfig {
  static settings: IAppConfig;
  constructor(private http: HttpClient) { }
  async load() {
    const jsonFile = `assets/config/config.${environment.name}.json`;
    await new Promise<void>((resolve, reject) => {
      this.http.get<IAppConfig>(jsonFile).toPromise().then((response: IAppConfig) => {
        AppConfig.settings = response;
        resolve();
      }).catch((response: any) => {
        reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
      });
    });
  }
}
