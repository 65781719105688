import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../config';
import { map, tap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';

import { PatientDocumentCategoryModel, PatientDocumentModel } from '../../models/patient';
import { Utils } from '../helpers';
import { Observable } from 'rxjs';
import { TreatmentDocumentationModel } from '../../models/patient/treatmentDocumentationModel';

@Injectable()
export class PatientDocumentsService {

  categories: PatientDocumentCategoryModel[] = null;

  constructor(private http: HttpClient,
    private messageService: MessageService) {}

  getPatientDocumentCategories(): Observable<PatientDocumentCategoryModel[]> {
    return this.http.get<PatientDocumentCategoryModel[]>(`${AppConfig.settings.apiServer.webApiURL}/PatientDocuments/Categories`)
    .pipe(map(categories => {
      this.categories = categories;
      return categories;
    }));
  }

  getPatientDocuments(inquiryID: number): Observable<PatientDocumentModel[]>{
    return this.http.get<PatientDocumentModel[]>(
      `${AppConfig.settings.apiServer.webApiURL}/PatientDocuments/?inquiryID=${inquiryID}`
    ).pipe(map(documents => {
      return documents;
    }));
  }

  getPatientTreatmentDocumentations(inquiryID: number): Observable<TreatmentDocumentationModel[]>{
    return this.http.get<TreatmentDocumentationModel[]>(
      `${AppConfig.settings.apiServer.webApiURL}/PatientDocuments/TreatmentDocumentations/?inquiryID=${inquiryID}`
    ).pipe(map(documents => {
      return documents;
    }));
  }

  uploadPatientDocument(inquiryID: number, file: File, categoryID?: number, category?: string, description?: string): Observable<any> {
    
    
    var formData = new FormData();

    formData.append("inquiryID", inquiryID.toString());
    if (categoryID)
      formData.append("categoryID", categoryID.toString());
    formData.append("category", category);
    formData.append("description", description);
    formData.append("file", file);

    return this.http.post(`${AppConfig.settings.apiServer.webApiURL}/PatientDocuments/`,
      formData,
        {
        headers: {
           'enctype': 'multipart/form-data'
        }
        })
      .pipe(tap(data => {
        return data;
      },
      error => {
        console.log(error);
        this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
      }));
  }

  deletePatientDocument(fileID: number) {
    return this.http.delete(`${AppConfig.settings.apiServer.webApiURL}/PatientDocuments/${fileID}`);
  }

   /**
     * Download the patient document
     * 
     * @param fileID FileID
     */
  getPatientDocumentFileData(fileID: number): Observable<ArrayBuffer> {
    return this.http.get(`${AppConfig.settings.apiServer.webApiURL}/PatientDocuments/${fileID}/Download`,
      { responseType: 'arraybuffer'});
  }

  /*
   * TODO
   * GetPatientDocumentFileData
   * */
}
