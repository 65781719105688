import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../../../core/services/alert.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilderService } from '../../../../core/services/formBuilder.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FBFormAssignmentModel } from '../../../../models/formBuilder/FormBuilderModels';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-notices',
  templateUrl: './notices.component.html',
  styleUrls: ['./notices.component.scss']
})
export class NoticesComponent implements OnInit {
  modalOptions: NgbModalOptions;
  currentModal: any;
  
  noticeComponent: boolean = false;
  noticesExist: boolean;
  loadingDoc = false;
  unacknowledgedNoticesExist: boolean = false;
  showAllNotices: boolean = false;
  showNoticeSection: boolean = false;
  public loginNotices: FBFormAssignmentModel[];
  public url: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilderService: FormBuilderService,
    private modalService: NgbModal,
    private alertService: AlertService,
  ) { 
    if (this.router.url === "/notices"){
      this.noticeComponent = true;
    }
    else{
      this.noticeComponent = false;
    }
  }

  ngOnInit() {
    this.route.parent.paramMap.pipe(switchMap((parentParams: ParamMap) => {
      return this.formBuilderService.getLoginNotices(this.showAllNotices);
    })).subscribe(notices => {
      if(notices != null && notices.length > 0){
        this.loginNotices = notices;
        this.noticesExist = true;
        this.unacknowledgedNoticesExist = notices.some(x => x.acknowledgedDate == null);
      }
    }, error => {});
    this.modalOptions = {
      keyboard: false,
      backdrop: "static",
      windowClass: 'my-class'
    };
  }

  openNotice(content, notice){
    if (notice.acknowledgedDate == null){
      this.alertService.confirm("Confirm", "By opening this notice you acknowledge that you have recieved it and will read it in it's entirety.").subscribe(answer => {
        if (answer) {
          console.log("Clicked to open Notice", notice);
          this.formBuilderService.getFormSubmissionDocToSign(notice.id).subscribe((arrayBuffer: any) => {
  
            this.url =
              URL.createObjectURL(new Blob([arrayBuffer], {
                type: 'application/pdf'
              }));
      
            this.loadingDoc = false;
          },
          error => {
              this.loadingDoc = false;
          });
          this.currentModal = this.modalService.open(content, this.modalOptions);
        }
      });
    }
    else{
      this.formBuilderService.getFormSubmissionDocToSign(notice.id).subscribe((arrayBuffer: any) => {
  
        this.url =
          URL.createObjectURL(new Blob([arrayBuffer], {
            type: 'application/pdf'
          }));
  
        this.loadingDoc = false;
      },
      error => {
          this.loadingDoc = false;
      });
      this.currentModal = this.modalService.open(content, this.modalOptions);
    }
  }
    
  getAcknowledgedColor(acknowledgedDate){
    if(acknowledgedDate == null){
      return "text-danger";
    }
    else{
      return "text-success";
    }
  }

  closeNoticeModal(){
    this.getLoginNotices(this.showAllNotices)
    this.currentModal.close();
  }

  getLoginNotices(showAll: boolean){
    this.route.parent.paramMap.pipe(switchMap((parentParams: ParamMap) => {
      return this.formBuilderService.getLoginNotices(showAll);
    })).subscribe(notices => {
      if(notices != null && notices.length > 0){
        this.loginNotices = notices;
        this.noticesExist = true;
        this.unacknowledgedNoticesExist = notices.some(x => x.acknowledgedDate == null);
      }
      else{
        this.loginNotices = [];
      }
    }, error => {});
  }

  dismissNotice(notice){
    console.log("Dismiss Notice", notice);
    if (notice.acknowledgedDate == null){
      this.alertService.error("Please view the notice before dismissing");
      return;
    }
    this.route.parent.paramMap.pipe(switchMap((parentParams: ParamMap) => {
      return this.formBuilderService.dismissNotice(notice.id);
    })).subscribe(notice => {
      if(notice){
        this.alertService.success("Notice Dismissed")
        this.getLoginNotices(this.showAllNotices)
      }
    }, error => {});
  }

  doToggleShowAllNotices(){
    if(this.showAllNotices == true){
      this.showAllNotices = false;
    }
    else{
      this.showAllNotices = true
    }

    this.getLoginNotices(this.showAllNotices);
  }

  getHeightForPDF() {
    if (window.innerHeight > 600) {
      return (window.innerHeight - 248) + 'px';
    } else {
      return '450px';
    }
  }
}
