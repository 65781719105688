import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class Utils {

  static getFormUrlEncoded(obj: Object) {
    const formBody = [];
    for (const property of Object.keys(obj)) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(obj[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    return formBody.join('&');
  }

  static downloadFile(blob, name) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([blob], { type: 'application/pdf' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = data;
    link.download = name;
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  }
}


export function getClientFromUrl() {
  const url = window.location.href;
  let clientFromUrl = '';
  if (url.indexOf('client.optomiser-development') >= 0) {
    clientFromUrl = 'beta';
  } else if (url.indexOf('localhost') >= 0) {
    clientFromUrl = 'localhost';
  } else if (url.indexOf('client.optomiser.com') >= 0) {
    clientFromUrl = 'utbs';
  } else if (url.indexOf('client-') >= 0) {
    clientFromUrl = url.substring(url.indexOf('//client-') + 9, url.indexOf('.'));
  }
  return clientFromUrl;
}

export function mergeObjects(obj1, obj2): any {
  const answer = {};
  for (const key in obj1) {
    if (answer[key] === undefined || answer[key] === null) {
      answer[key] = obj1[key];
    }
  }
  for (const key in obj2) {
    if (answer[key] === undefined || answer[key] === null) {
      answer[key] = obj2[key];
    }
  }
  return answer;
}

export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}
