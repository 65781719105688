import { Component, OnInit } from '@angular/core';
import { PatientModel } from '../../../models/patient';
import { PatientInvoiceModel } from '../../../models/client';
import {
  AccountService,
  PatientsService,
  PatientInvoiceService,
  AlertService,
  ClientMessagingService,
  AppConfig
} from '../../../core';
import { first, switchMap, tap } from 'rxjs/operators';
import { Router, ParamMap, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { GeneralSettingsModel } from '../../../models/account/generalSettingsModel';
import { MessageThreadModel } from '../../../models/messaging/';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilderService } from '../../../core/services/formBuilder.service';
import { FBFormAssignmentModel } from '../../../models/formBuilder/FormBuilderModels';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public canSendReceiveMessage: boolean = false;
  public showAll: boolean = false;
  public showNotice = false;
  public loadingPatients = false;
  public loadingInvoices = false;
  public loadingMessages = false;
  creditCardPaymentEnabled: boolean = false;
  public unreadThreads: MessageThreadModel[];
  
  get generalSettings(): GeneralSettingsModel {
    return this.accountService ? this.accountService.generalSettings : null;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private accountService: AccountService,
    private patientsService: PatientsService,
    public patientInvoiceService: PatientInvoiceService,
    private clientMessagingService: ClientMessagingService,
    private formBuilderService: FormBuilderService,
    private alertService: AlertService,
  ) {
    this.loadingPatients = true;
    patientsService.$patients.subscribe(data => {

      this.checkNotice();

    });

    this.clientMessagingService.$unreadMessages.subscribe(messages => {
      this.canSendReceiveMessage = this.clientMessagingService.canSendReceiveMessage;
      this.unreadThreads = messages
    });
    this.clientMessagingService.$receivers.subscribe(data => {
      this.canSendReceiveMessage = this.clientMessagingService.canSendReceiveMessage;
    });
  }
  
  get loggedUserfirstName() {
    return this.accountService.currentUser.firstName;
  }
  get currentPatientID() {
    return this.patientsService.currentPatientID;
  }
  get currentInquiryID() {
    return this.patientsService.currentInquiryID;
  }

  loadUnreadMessages() {
    this.loadingMessages = true;
    this.clientMessagingService.$unreadMessages.subscribe(data => {
      this.unreadThreads = data;
      this.loadingMessages = false;
    });
  }

  hasActionRequired(patient): boolean {
    return (!this.formsAndDocsSigned(patient) || patient.insurancesReviewPending);
  }

  formsAndDocsSigned(patient) {
    return patient != null
      && !(patient.requiredFormsNotSigned > 0
        || (!patient.hasAllRequiredDocsSigned
          && patient.documentsNotSigned > 0));
  }

  get openPatientInvoices() {
    let vls = [];
    if (this.patientInvoiceService.PatientInvoices != null) {
      for (let i = 0; i < this.patientInvoiceService.PatientInvoices.length; i++) {
        if (this.patientInvoiceService.PatientInvoices[i].amountDue > 0)
          vls.push(this.patientInvoiceService.PatientInvoices[i]);
      }
    }

    return vls;
  }
  
  get totalAmountDue() {
    let vl = 0;
    if (this.patientInvoiceService.PatientInvoices != null) {
      for (let i = 0; i < this.patientInvoiceService.PatientInvoices.length; i++) {
        vl += this.patientInvoiceService.PatientInvoices[i].amountDue;

      }
    }

    return vl;
  }

  get patients() {
    return this.patientsService.patients;
  }

  isPastDue(invoiceDueDate) {
    return moment(invoiceDueDate).isBefore(moment().subtract(1, 'days').endOf('day'));
  }

  ngOnInit() {
    if (this.patients == null) {
      this.showAll = false;
      //  this._client.getAll().subscribe((vl) => { });
    } else {
      this.showAll = true;

      // Notice
      this.checkNotice();

    }

    this.creditCardPaymentEnabled = this.accountService
      && this.accountService.currentUser
      && this.accountService.currentUser.featureSwitchIDs
      && !!this.accountService.currentUser.featureSwitchIDs.find(x => x === 82);

    this.route.queryParams.subscribe(params => {
      this.showAll = (params['show'] == "all") || (this.patients != null);
      // console.log('Show All: ' + this.showAll + ' Clients: ' + (this.clients != null ? this.clients.length : -1));
    });

    this.clientMessagingService.getMessageThreads().subscribe(messages => { });

  }

  checkNotice() {
    const endDate = new Date('2022-10-15');
    const today = new Date();
    if (today < endDate) {
      this.showNotice = this.patients.some(patient => patient.hasMedClinicAuthorization || patient.hasPsychMedAuthorization);
    }
  }

  signFile(form: FBFormAssignmentModel) {
    this.router.navigate([`/patient/${form.inquiryID}/forms/sign/${form.formSubmissionID}`], { queryParams: { formName: form.form.name } });
  }
  
  get unreadThreadsCount() {
    return this.unreadThreads.length;
  }

  goToThread(thread: MessageThreadModel) {
    this.router.navigate(['/messaging/message-view/'+  thread.messageThreadID]);
  }

  get urlForPictureRequest() {
    return `${AppConfig.settings.apiServer.webApiURL}/Messages/SenderPhoto/`;
  }

  getPhoto(photoID?: number) {
    return `${AppConfig.settings.apiServer.webApiURL}/Messages/SenderPhoto/${photoID}`;
  }
}
