
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuModule } from 'primeng/menu';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToastModule } from 'primeng/toast';
import { NavMenuComponent } from './components';
import { AlertComponent } from './directives';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import {DialogModule} from 'primeng/dialog';
import { SessionTimerComponent } from './components/session-timer/session-timer.component';

const components = [
  NavMenuComponent,
  ValidationMessageComponent,
  SessionTimerComponent
];

const primeNgModules = [
  DropdownModule,
  MultiSelectModule,
  CalendarModule,
  DialogModule,
];

const directives = [
  AlertComponent
];

@NgModule({
  imports: [
    CommonModule,
    MenuModule,
    AutoCompleteModule,
    ToastModule,
    FormsModule,
    ReactiveFormsModule,
    PdfJsViewerModule,
    ...primeNgModules
  ],
  declarations: [
    ...components,
    ...directives
  ],
  exports: [
    RouterModule,
    MenuModule,
    AutoCompleteModule,
    ToastModule,
    FormsModule,
    ReactiveFormsModule,
    PdfJsViewerModule,
    ...directives,
    ...components,
    ...primeNgModules
  ]
})
export class SharedModule {}
