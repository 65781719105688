<ng-container *ngIf="form &&form.get(path)">
<small  *ngIf="form.get(path).invalid && (form.get(path).dirty || form.get(path).touched)"
  class="text-danger"
>
  {{
    form.get(path).errors.required
      ? label + " is required"
      : label=='Do you have legal authority to enroll the client in services and consent to treatment?'
      ? "You must have legal authority to enroll the client in services and consent to treatment to complete Intake Paperwork. If you have questions, please contact the office."
      : form.get(path).errors.email
      ? "Invalid " + label

      : form.get(path).errors.pattern
      ? "Invalid " + label
      : form.get(path).errors.maxlength
      ? label +
        " max length is " +
        form.get(path).errors.maxlength.requiredLength
      : form.get(path).errors.min
      ? label +
        " min value is " +
        form.get(path).errors.min.min
      : form.get(path).errors.max
      ? label +
        " max value is " +
        form.get(path).errors.max.max
      : form.get(path).errors.confirmNotEqual
      ? "Confirmation field must match"
      :form.get(path).errors.genericMessage
      ? form.get(path).errors.genericMessage
      :""
  }}</small
>
</ng-container>