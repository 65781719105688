import { GeneralSettingsModel } from './models/account/generalSettingsModel';
import { Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import { AppConfig } from './core';
import { AccountService } from '../app/core/services/account.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { getClientFromUrl } from './core/helpers/utils';
declare let ga: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';

  generalSettings(): GeneralSettingsModel {
    return this.accountService ? this.accountService.generalSettings : null;
  }
  public constructor(
    private accountService: AccountService,
    private titleService: Title,
    private router: Router,
    private http: HttpClient,
  ) {
    ga('create', AppConfig.settings.googleUAID, 'auto');
    const clientFromUrl = getClientFromUrl();
    ga('set', 'app_name', 'Client Portal');
    ga('set', 'client', clientFromUrl);
    ga('set', 'page_type', 'Angular');
    this.accountService.getGeneralSettings().subscribe(x => {
      this.titleService.setTitle(this.accountService.generalSettings.businessFullName);
    });



    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let urlTree = this.router.parseUrl(event.urlAfterRedirects);
        let parameters = JSON.stringify(urlTree.queryParams);
        urlTree.queryParams = {};
        let cleanUrl = urlTree.toString();

        this.accountService.logNavigation(cleanUrl, parameters)
          .pipe()
          .subscribe(
            data => { },
            error => { });

        ga('set', 'page', cleanUrl);
        ga('send', 'pageview');
        (window as any).NavigationRouterState = 'NavigationEnd: ' + cleanUrl;
      } else if (event instanceof NavigationStart) {
        (window as any).NavigationRouterState = 'NavigationStart';
      }
      else if (event instanceof NavigationCancel) {
        (window as any).NavigationRouterState = 'NavigationCancel';
      }
      else if (event instanceof NavigationError) {
        (window as any).NavigationRouterState = 'NavigationError';
      }
      console.debug((window as any).NavigationRouterState);
    });
  }

  @HostListener("window:message", ["$event"])
  OnWindoMessage( $event: MessageEvent) {
    
    console.log(`Received message: ${$event.data} from ${$event.origin}`);
    
    if ($event.origin.indexOf("optomiser.com") == -1
      && $event.origin.indexOf("optomiser-development.com") == -1)
    //  return;

    if (!$event.data)
        return;
    try {
      
      var message = JSON.parse($event.data);
     

    } catch (ex) {

    }
  }
}
