import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared';

import { LoginComponent } from './login/login.component';
import { RegisterNewComponent } from './register/register-new.component';
import { RegisterExistingComponent } from './register/register-existing.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { SetPasswordComponent } from './set-password/set-password.component';

import { AuthRoutingModule } from './auth-routing.module';

const pages = [
  LoginComponent,
  RegisterNewComponent,
  RegisterExistingComponent,
  PasswordResetComponent,
  SetPasswordComponent
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AuthRoutingModule
  ],
  declarations: [
    ...pages
  ],
  providers: []
})
export class AuthModule {}
