import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { LoginComponent } from './login/login.component';
import { RegisterNewComponent } from './register/register-new.component';
import { RegisterExistingComponent } from './register/register-existing.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { SetPasswordComponent } from './set-password/set-password.component';

import { AuthGuard } from '../../core';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'register-new-patient', component: RegisterNewComponent },
  { path: 'register-existing-patient', component: RegisterExistingComponent },
  { path: 'password-reset', component: PasswordResetComponent },
  { path: 'set-password', component: SetPasswordComponent, canActivate: [AuthGuard]  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AuthRoutingModule {}

