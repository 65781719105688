import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared';


import { PublicRoutingModule } from './public-routing.module';
import { CheckSignedDocumentComponent } from './check-signed-document/check-signed-document.component';

const pages = [
  CheckSignedDocumentComponent
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PublicRoutingModule
  ],
  declarations: [
    ...pages,
    CheckSignedDocumentComponent
  ],
  providers: [
  ]
})
export class PublicModule {}
