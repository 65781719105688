import { AccountService } from './../services/account.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  timer;
  constructor(private accountService: AccountService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // add authorization header with jwt token if available
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    
    if (currentUser && currentUser.token) {
      // Auto logout: After 5 minutes and 15 seconds of no calls to backend, do a call that may cause a 401 because the token expired

      if (request && request.url && request.url.indexOf("/PatientSchedule/OpenDoxyMeSessions") < 0) {

        var d = new Date();
        if (window.localStorage) {
          window.localStorage.setItem("ClientPortalLastServerCall", d.toString());
        }
        /*
        if (this.timer) {
          clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
          this.accountService.me().subscribe();
        }, 5 * 60 * 1000 + 15);
        */
      }
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`
        }
      });
    }

    return next.handle(request).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse && request && request.url && request.url.indexOf("/PatientSchedule/OpenDoxyMeSessions") < 0) {
          // update the token with the new one generatd on every request to increase the expiration to more 2 hours
          const newToken = evt.headers.get('newtoken');
          const currentUser = JSON.parse(localStorage.getItem('currentUser'));
          if (newToken && currentUser && currentUser.token !== newToken) {
            currentUser.token = newToken;
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
            this.accountService.currentUser.token = newToken;
          }
        }
      })
    );
  }
}
