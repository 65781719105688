import { OnInit, Input, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-validation-message',
  templateUrl: 'validation-message.component.html'
})

export class ValidationMessageComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() path: string;
  @Input() name: string;
  @Input() label: string;

constructor() { }

ngOnInit() {
}

}
