import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AppConfig } from '../config';
import { map, tap } from 'rxjs/operators';
import { Utils } from '../helpers/utils';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';

import { ClientStatementLineItemModel } from '../../models/patient';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class PatientStatementService {


  
  clientStatementLineItems: ClientStatementLineItemModel[];
  $clientStatementLineItems = new Subject<ClientStatementLineItemModel[]>();


  constructor(private http: HttpClient,
    private messageService: MessageService) { }


  public getPatientStatement(patientID: number, startDate?: Date, endDate?: Date): Observable<ClientStatementLineItemModel[]> {
    
    let params = new HttpParams().set("patientID", patientID.toString());
    if (startDate !==null)
      params = params.set("startDate", moment(startDate).format('YYYY-MM-DD')); 
    if (endDate !==null)
      params = params.set("endDate", moment(endDate).format('YYYY-MM-DD')); 

    return this.http.get<ClientStatementLineItemModel[]>(`${AppConfig.settings.apiServer.webApiURL}/PatientStatement`, { params: params } )
      .pipe(tap(clientStatementLineItems => {
        if (clientStatementLineItems) {
          console.debug(clientStatementLineItems.length + ' client statement line items loaded');
          this.clientStatementLineItems = clientStatementLineItems;
          this.$clientStatementLineItems.next(clientStatementLineItems);
        }
        return clientStatementLineItems;
      },
        error => {
          console.log(error);
          this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
        }));

  }

   /**
     * Download the patient document
     * 
     * @param fileID FileID
     */
  getPatientStatementPDF(patientID?: number, startDate?: Date, endDate?: Date) {
    
    let params = new HttpParams().set("patientID", patientID.toString());
    if (startDate !==null)
      params.set("startDate", moment(startDate).format('YYYY-MM-DD')); 
    if (endDate !==null)
      params.set("endDate", moment(endDate).format('YYYY-MM-DD')); 

    return this.http.get(`${AppConfig.settings.apiServer.webApiURL}/PatientStatement/Download` , 
      {
        params: params,
        responseType: 'arraybuffer',
        observe: 'response' 
      });
  }
}
