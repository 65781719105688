<p-dialog header="Important Notice from Utah Behavior Services"
[focusTrap]="false" [modal]="true" [styleClass]="'top-dialog'" [style]="{width: '70vw'}"
[(visible)]="this.showNotice">
 <p> Utah Behavior Services is committed to the valuable service it offers our patients and families through our Medical Clinic. We look forward to its future and seeing the team grow. We stand behind the importance of efficiently and effectively integrating care with your Mental Health and/or Applied Behavior Analysis team (s).
</p><p>At this time, Tamara Broadhead has accepted a position with another practice.  We are actively working through the transition and have requested that her last day with Utah Behavior Services be October 13th. She values the time she had in working with each and every one of her patients. The opportunity to work with Tamara has been a pleasure, and we wish her nothing but the best in her future endeavors.
</p><p>We are engaged in the process of interviewing and hiring qualified and skilled providers that will be able to take over your care if you choose. For anyone who would like to seek other medical resources your funding sources provider list or psychologytoday.com are avenues to do so.
</p><p>Please feel free to reach out to our office at 801-255-5131, ext. 2, for further questions or concerns. The Medical Clinic team is happy to assist in any way possible if any issues need to be resolved before October 13th, 2022.
</p><p>Utah Behavior Services</p>
</p-dialog>
<h1>Hello {{loggedUserfirstName}}!</h1>
<div class="row">
  <div class="col-lg-6 col-md-12">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Welcome to {{generalSettings?.businessFullName}} client portal.</h5>
        <div *ngIf="patients == null">
          Loading registered patients...
        </div>
        <div *ngIf="patients != null">
          <h6>
            Click a name below to access your available client information.
          </h6>
          <h6 class="">Registered patients:</h6>

          <ul>
            <li *ngFor="let patient of patients">
              <a [routerLink]="['/patient/'+ patient.inquiryID ]"
                 class=""
                 [ngClass]="{
            'text-danger': this.hasActionRequired(patient)
          }">
                {{patient.firstName}} {{patient.lastName}}  {{patient.birthdate | date: 'M/d/yy'}}
                <span *ngIf="hasActionRequired(patient)" class="text-danger">[Action Required]</span>
              </a>
              <span *ngIf="patient.hasOptumMedicaid">
                Our records indicate you have Optum Medicaid as an insurance provider.
                <a  href="https://optumhealthslco.com/" target="_blank">View your Medicaid Member Handbook HERE by looking under the section "Medicaid Member Handbook."</a>
              </span>
            </li>
          </ul>
          <!--<a class="btn btn-primary" [routerLink]="['/patient/add']" >Add patient</a>-->
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6 col-md-12 mt-md-3 mt-lg-0" *ngIf="creditCardPaymentEnabled && patients != null">
    <div class="card">
      <div class="card-body">
        <div class="text-center">
          <h6>
            Do you have an upcoming appointment, and want to pay your copay now?
          </h6>
          <h6>
            Click below to make that payment.
          </h6>
        </div>
        <div class="mt-3 text-center">
          <button class="btn btn-primary m-3" [routerLink]="['/client/payment']">Pay Copay</button>
        </div>
      </div>
    </div>
  </div>
  
  <!-- CURRENT NOTICES -->
  <div class="col-12 m-0 p-0">
    <app-notices></app-notices>
  </div>

  <div class="col-12 mt-3" *ngIf="openPatientInvoices!=null && openPatientInvoices.length>0">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Outstanding Invoices</h5>
        <table class="table table-stripe ">
          <thead>
            <tr>
              <th>
                Invoice #
              </th>
              <th class="text-center">Date</th>
              <th class="text-center">Due</th>
              <th >
                Patient
              </th>
              <th class="text-right">
                Amount
              </th>
              <th class="text-right">
                Due
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let inv of openPatientInvoices">
              <td>
                <a href="#" [routerLink]="['/client/invoice/'+  inv.patientInvoiceID ]" [class.button-loading]="inv.invoiceDownloading" title="Download PDF">
                  {{inv.invoiceNumber}}
                  <i class="fal fa-file-pdf ml-1"></i>
                </a>
              </td>
              <td class="text-center">
                {{inv.invoiceDate | date}}
              </td>
              <td class="text-center" [class.text-danger]="isPastDue(inv.invoiceDueDate)">
                {{inv.invoiceDueDate | date}}
                <i class="fal fa-comment-alt-exclamation" *ngIf="isPastDue(inv.invoiceDueDate)" title="Past due"></i>
              </td>
              <td >
                <div *ngFor="let p of inv.patientNames">
                  {{p}}
                </div>
              </td>
              <td class="text-right">
                {{inv.totalAmount | currency }}
              </td>
              <td class="text-right">
                <a class=" ml-3" *ngIf="creditCardPaymentEnabled && inv.amountDue>0" [routerLink]="['/client/payment']" [queryParams]="{invoiceNumber:inv.invoiceNumber, amount: inv.amountDue}" title="Make Payment">
                  {{inv.amountDue | currency }}
                  <i class="fal fa-credit-card-front ml-1"></i>
                </a>
                <span *ngIf="!creditCardPaymentEnabled && inv.amountDue>0">
                  {{totalAmountDue | currency }}
                </span>
                <span *ngIf="inv.amountDue<=0">-</span>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="4">
              </td>
              <td class="text-right font-weight-bold" colspan="2">
                Total Amount Due:
                <a class=" ml-3" *ngIf="creditCardPaymentEnabled && totalAmountDue>0" [routerLink]="['/client/payment']" [queryParams]="{amount: totalAmountDue}" title="Make Payment">
                  {{totalAmountDue | currency }}
                  <i class="fal fa-credit-card-front ml-1"></i>
                </a>
                <span *ngIf="!creditCardPaymentEnabled && totalAmountDue>0">
                  {{totalAmountDue | currency }}
                </span>
                <span *ngIf="totalAmountDue<=0">-</span>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>

  <!-- MESSAGING -->
  <div class="col-12 mt-3" *ngIf="canSendReceiveMessage && unreadThreads !=null && unreadThreads.length > 0">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <h5 class="m-1">Unread messages</h5>
          <button class="btn btn-primary align-top d-none" [routerLink]="['/messaging/message-send']">New Message</button>
          <button class="btn btn-primary align-top d-none"  [routerLink]="['/messaging/message-list']">Message List</button>
        </div>
        <div class="m-0 p-0 unreadThread" *ngFor="let messageThread of unreadThreads">

          <a href="#" class="list-group-item list-group-item-action" [routerLink]="['/messaging/message-view/'+messageThread.messageThreadID]">
            <div class="float-right">
              {{messageThread.date | date:'short'}}
            </div>
            <img  *ngIf="messageThread.patientPhotoID !=null"  [src]="urlForPictureRequest + messageThread.patientPhotoID | secure | async" class="item-profile-photo" style="float:left; margin-right: 5px; margin-top:5px;" />
            <div class="font-weight-bolder" title="Patient Name">{{messageThread.patientName}}</div>
            <span *ngIf="messageThread.unread>0" class="float-right  badge  badge-pill badge-success " title="Has unread messages">{{messageThread.unread}}</span>
            <div>{{messageThread.threadName}}</div>
          </a>

        </div>
          
      </div>
    </div>
  </div>

