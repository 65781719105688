import { GeneralSettingsModel } from './../../models/account/generalSettingsModel';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { AppConfig } from '../config';
import { LoginModel, LoginRegisterModel } from '../../models/account';
import { MessageService } from 'primeng/api';

import { Utils, mergeObjects } from '../helpers/utils';
import { Observable } from 'rxjs';
declare let ga: any;


@Injectable()
export class AccountService {

  currentUser: LoginModel;
  generalSettings: GeneralSettingsModel;

  constructor(private http: HttpClient,
    private messageService: MessageService) {
    const currentUserJ = localStorage.getItem('currentUser');
    if (currentUserJ != null) {
      this.currentUser = JSON.parse(currentUserJ);
      ga('set', 'userId', this.currentUser.id + '');

    } else {
      this.currentUser = null;
    }
  }


  static getApplicationURL() {
    return location.protocol + '//' + location.host;
  }


  /**
    * Authenticate with credentials and retrives login profile
    *
    * @param userName
    * @param password
    */
  authenticate(userName: string, password: string): Observable<LoginModel> {
    return this.http.post<LoginModel>(
      `${AppConfig.settings.apiServer.webApiURL}/Account/authenticate`,
      Utils.getFormUrlEncoded({
        userName: userName,
        password: password
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    ).pipe(map(user => {
      // login successful if there's a jwt token in the response
      if (user && user.token) {

        ga('set', 'userId', user.id + '');
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUser = {
          userName: userName,
          password: password,
          ...this.currentUser,
          ...user

        };
      }
      return user;
    }));
  }

  /**
     * Authenticate with an access token and retrives login profile
     *
     * @param tokenCode
     */
  authenticateToken(tokenCode: string): Observable<LoginModel> {

    const body = new HttpParams()
      .set('tokenCode', tokenCode);

    return this.http.post<LoginModel>(
      `${AppConfig.settings.apiServer.webApiURL}/Account/authenticatetoken`, body
    ).pipe(map(user => {
      ga('set', 'userId', user.id + '');
      // login successful if there's a jwt token in the response
      if (user && user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUser = {
          ...this.currentUser,
          ...user
        };
      }

      return user;
    }));
  }

  /**
    * Register a new user login profile with a patient link
    *
    * @param loginRegisterModel
    */
  register(registerUser: LoginRegisterModel) {
    registerUser.applicationURL = AccountService.getApplicationURL();
    return this.http.post<LoginModel>(`${AppConfig.settings.apiServer.webApiURL}/Account/register`, registerUser);
  }


  /**
     * Get the user login profile
     */
  me(): Observable<LoginModel> {
    
    return this.http.get<LoginModel>(`${AppConfig.settings.apiServer.webApiURL}/Account`)
      .pipe(map(user => {
        if (user) {
          let token = this.currentUser.token;
          this.currentUser = {
            ...this.currentUser,
            ...user,
          };
          this.currentUser.firstName = user.firstName != null ? user.firstName : this.currentUser.firstName;
          this.currentUser.lastName = user.lastName != null ? user.lastName : this.currentUser.lastName;
          this.currentUser.address = user.address != null ? user.address : this.currentUser.address;
          this.currentUser.city = user.city != null ? user.city : this.currentUser.city;
          this.currentUser.state = user.state != null ? user.state : this.currentUser.state;
          this.currentUser.zip = user.zip != null ? user.zip : this.currentUser.zip;
          if (!this.currentUser.token){
            this.currentUser.token = token;
          }
          localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
        }

        return user;
      }));
  }

  /**
     * Updates the user login profile and password
     *
     * @param loginUpdateModel
     */
  update(loginUpdateModel: LoginModel) {

    const data: LoginModel = {
      ...this.currentUser,
      ...loginUpdateModel
    };
    return this.http.put<any>(`${AppConfig.settings.apiServer.webApiURL}/Account`,
      data).pipe(
        tap(
          data => {
            let token = this.currentUser.token;
            this.currentUser = {
              ...this.currentUser,
              ...loginUpdateModel
            };
            if (!this.currentUser.token){
              this.currentUser.token = token;
            }
            this.currentUser.password = null;
            localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
            //return this.me().subscribe(profile=> this.currentUser = profile);

          },
          error => {
            console.log(error);
            this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
          }
        )
      );;
  }


  /**
     * Deletes the user login profile
     */
  delete() {
    return this.http.delete<any>(`${AppConfig.settings.apiServer.webApiURL}/Account`);
  }

  /**
     * Request a new password. An access token will be sent to the user email.
     *
     * @param email
     */
  requestpasswordreset(email: string) {

    let wholeEmail = []
    wholeEmail = email.split('@');
    let encodedEmail = encodeURIComponent(wholeEmail[0]);

    const body = new HttpParams()
      .set('email', encodedEmail)
      .set('domain', wholeEmail[1])
      .set('applicationURL', AccountService.getApplicationURL());

    return this.http.post(`${AppConfig.settings.apiServer.webApiURL}/Account/requestpasswordreset`, body);
  }

  //////

  logNavigation(url: string, parameters: string) {

    if (url.startsWith('/'))
      url = window.location.protocol + '://' + window.location.hostname + url

    const context = localStorage.getItem('currentUser');

    return this.http.post(`${AppConfig.settings.apiServer.webApiURL}/Account/lognavigation`, { Url: url, Parameters: parameters, Context: context });
  }


  logout() {
    // remove user from local storage to log user out
    this.currentUser = null;
    ga('set', 'userId', '');
    localStorage.setItem('currentUser', null);
    localStorage.removeItem('currentUser');
  }


  getGeneralSettings() {
    return this.http.get(`${AppConfig.settings.apiServer.webApiURL}/Account/GeneralSettings`).pipe(
      tap(data => {
        this.generalSettings = data as GeneralSettingsModel;
      }, error => { }));
  }

}
