import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getClientFromUrl } from './utils';

@Injectable()
export class ClientInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add client  header
    const url = window.location.href;
    const clientFromUrl = getClientFromUrl();
    request = request.clone({
      setHeaders: {
        'clientname': clientFromUrl
      }
    });

    return next.handle(request);
  }
}
