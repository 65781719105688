
import { AbstractControl, ValidatorFn } from '@angular/forms';

export function DateValidator(notFuture = false): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    var isValid = ( /^(\d{1,2}\/\d{1,2}\/(\d{2}|\d{4}))|(\d{4}-\d{2}-\d{2})$/.test(control.value));
    if (isValid) {
      var dateObject = new Date(control.value);
      if (isNaN(dateObject.getTime()))
        isValid = false;
      else if (dateObject.getFullYear() < 1753)
        isValid = false;
      else if (dateObject.getFullYear() > 2199)
        isValid = false;
      else if (notFuture && dateObject > new Date()) {
        isValid = false;
      }
    }

    return !isValid ? {'invalidDate': { value: control.value }} : null;
  };
}
