import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AppConfig } from '../config';
import { map, tap } from 'rxjs/operators';
import { Utils } from '../helpers/utils';
import { MessageService } from 'primeng/api';
import { PaymentPlanModel } from '../../models/patient/payment-plan-model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentPlanService {

  constructor(private http: HttpClient,
    private messageService: MessageService) { }

    public getPaymentPlans(id: number){
      return this.http.get<PaymentPlanModel[]>(`${AppConfig.settings.apiServer.webApiURL}/GetPaymentPlanInfo/${id}` )
      .pipe(tap(paymentPlans => {
        return paymentPlans;
      },
        error => {
          console.log(error);
          this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
        }));
    }
}
