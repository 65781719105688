<p-dialog [(visible)]="showSessionTimeoutWarning" [style]="{ width: '50vw' }" [closable]="false" [closeOnEscape]="false" [header]="title" [modal]="true" [draggable]="false" [resizable]="false">
  
  <p class="m-0">
    {{message}}
  </p>

  
  <div class="d-flex justify-content-center" *ngIf="hideFooter == false">
    
      <p-button icon="pi pi-check" (click)="onKeepIn()" [label]="keepAliveButton" class="mr-1" styleClass="p-button-text"></p-button>
      <p-button icon="pi pi-sign-out" (click)="onLogout()" [label]="logoutButton" class="ml-1" styleClass="p-button-text"></p-button>
    
  </div>
</p-dialog>
