import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../config';
import { map, tap } from 'rxjs/operators';
import { Utils } from '../helpers/utils';
import { MessageService } from 'primeng/api';

import { PatientModel, RegisterPatientModel, PatientInformationModel  } from '../../models/patient';
import { Subject, Observable } from 'rxjs';
import { PatientIntakeService } from './patientIntake.service';

@Injectable()
export class PatientsService {

  currentPatient: PatientInformationModel;
  patientIntakeService: PatientIntakeService;

  patients: PatientModel[];
  $patients = new Subject<PatientModel[]>();

  lastSignedInquiryId?: number;
  lastSignedInquiryDate?: Date;


  constructor(private http: HttpClient,
    private messageService: MessageService) {}

  get currentPatientID() {
    return (this.currentPatient != null) ? this.currentPatient.patientID : null;
  }
  get currentInquiryID() {
    return (this.currentPatient != null) ? this.currentPatient.inquiryID : null;
  }

  getPatients() {
    return this.http.get<PatientModel[]>(`${AppConfig.settings.apiServer.webApiURL}/Patients`)
      .pipe(tap(patients => {
        if (patients) {
          console.debug(patients.length + ' Patients loaded');
          this.patients = patients;
          this.$patients.next(patients);
        }
      return patients;
    },
      error => {
        console.log(error);
        this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
      }));
  }

  getPatient(inquiryID: number): Observable<PatientInformationModel> {
    return this.http.get<PatientInformationModel>(`${AppConfig.settings.apiServer.webApiURL}/Patients/` + inquiryID)
      .pipe(tap(patient => {
        if (patient) {
          console.debug('Patient ' + inquiryID + ' loaded (' + patient.lastName + ', ' + patient.firstName +')');
          if (this.lastSignedInquiryId == inquiryID
            && this.lastSignedInquiryDate != null
            && this.lastSignedInquiryDate.getTime() > new Date().getTime() - 1000 * 60 * 5) {
            patient.signedDate = this.lastSignedInquiryDate;
            patient.signatureRequestPending = false;
            patient.isNewClient = false;
          }

          this.currentPatient = {
            ...this.currentPatient,
            ...patient

          };

        }
        return patient;
      },
        error => {
          console.log(error);
          this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
        }));
  }

  deletePatient(inquiryID: number) {
    return this.http.delete(`${AppConfig.settings.apiServer.webApiURL}/Patients/` + inquiryID);
  }


  addPatient(patientRegisterModel: RegisterPatientModel): Observable<PatientInformationModel> {

    return this.http.post<PatientModel>(`${AppConfig.settings.apiServer.webApiURL}/Patients`, patientRegisterModel).pipe(tap(patient => {
      if (patient) {

        this.currentPatient = patient as PatientInformationModel;
      }
      return patient;
    },
      error => {
        console.log(error);
        this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
      }));
  }


  setPatient(patientData: PatientInformationModel): Observable<any>  {

    return this.http.put<PatientModel>(`${AppConfig.settings.apiServer.webApiURL}/Patients/` + patientData.inquiryID, patientData)
      .pipe(tap(data => {
        return data;
      },
      error => {
        console.log(error);
        this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
      }));
  }


  setPatientPhoto(inquiryID: number, photoType: string, file: File): Observable<any>  {


    var formData = new FormData();
    formData.append("photoType", photoType);
    formData.append("file", file);

    return this.http.post(`${AppConfig.settings.apiServer.webApiURL}/Patients/${inquiryID}/photo`,
      formData,
        {
        headers: {
           'enctype': 'multipart/form-data'
        }
        })
      .pipe(tap(data => {
        return data;
      },
      error => {
        console.log(error);
        this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
      }));
  }


}
