import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../shared';
import { MainRoutingModule } from './main-routing.module';
import { HomeComponent,  PatientAddComponent } from './home';
import { MainComponent } from './main.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PaymentPlanComponent } from './patient/payment-plan/payment-plan.component';
import { SecurePipeModuleModule } from '../secure-pipe-module/secure-pipe-module.module';
import { NoticesComponent } from './notices/notices/notices.component';

const pages = [
  MainComponent,
  HomeComponent,
  PatientAddComponent,
  PaymentPlanComponent
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MainRoutingModule,
    SecurePipeModuleModule,
    NgbModule
  ],
  declarations: [
    ...pages,
    PageNotFoundComponent,
    NoticesComponent,
  ],
  providers: []
})
export class MainModule {}
