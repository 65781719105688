import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AppConfig } from '../config';
import { map, tap } from 'rxjs/operators';
import { Utils } from '../helpers/utils';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';

import { CreditCardReceiptModel, CreditCardProfileModel, CreditCardTransactionModel, PatientPaymentPlanModel } from '../../models/client';
import { Subject, Observable } from 'rxjs';


@Injectable()
export class ClientCreditCardService {
  
    CreditCardReceipts: CreditCardReceiptModel[];
    $CreditCardReceipts = new Subject<CreditCardReceiptModel[]>();

    PatientPaymentPlans: PatientPaymentPlanModel[];
    $PatientPaymentPlans = new Subject<PatientPaymentPlanModel[]>();
  
    ClientCreditCards: CreditCardProfileModel[];
    $ClientCreditCards = new Subject<CreditCardProfileModel[]>();

    constructor(private http: HttpClient,
    private messageService: MessageService) { }

  // TransactionReceipt


    /**
     * Get the list of the Credit Card Transaction Receipts
     * 
     * @param startDate 
     * @param endDate 
     * @param start 
     * @param length 
     */  
    public getCreditCardTransactionReceipts(startDate?: Date, endDate?: Date, start: number = 0, length: number = -1): Observable<CreditCardReceiptModel[]> {

      let params = new HttpParams();
      if (startDate !==null)
        params = params.set("startDate", moment(startDate).format('YYYY-MM-DD')); 
      if (endDate !==null)
        params = params.set("endDate", moment(endDate).format('YYYY-MM-DD')); 
      if (start >0)
        params = params.set("start", start.toString()); 
      if (length >0)
        params = params.set("length", length.toString()); 

      return this.http.get<CreditCardReceiptModel[]>(`${AppConfig.settings.apiServer.webApiURL}/ClientCreditCard/TransactionReceipts`, { params: params } )
        .pipe(tap(creditCardReceipts => {
          if (creditCardReceipts ) {
            console.debug(creditCardReceipts .length + ' Credit Card Transaction Receipts loaded');
            this.CreditCardReceipts = creditCardReceipts ;
            this.$CreditCardReceipts.next(creditCardReceipts );
          }
          return creditCardReceipts ;
        },
          error => {
            console.log(error);
            this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
          }));

    }

    /**
     * Download transaction receipt in PDF
     * 
     * @param cardConnectTransactionID 
     */
    public downloadCreditCardTransactionReceipt(cardConnectTransactionID?: number) {
    
      let params = new HttpParams().set("cardConnectTransactionID", cardConnectTransactionID.toString());
    
      return this.http.get(`${AppConfig.settings.apiServer.webApiURL}/ClientCreditCard/DownloadReceipt` , 
        {
          params: params,
          responseType: 'arraybuffer',
          observe: 'response' 
        });
    }

  // CreditCards
  

    /**
     * 
    Get the list of the Credit Cards in file
     */  
    public getCreditCards(): Observable<CreditCardProfileModel[]> {

     

      return this.http.get<CreditCardProfileModel[]>(`${AppConfig.settings.apiServer.webApiURL}/ClientCreditCard/`, {  } )
        .pipe(tap(clientCreditCards => {
          if (clientCreditCards ) {
            console.debug(clientCreditCards .length + ' Credit Card Profiles loaded');
            this.ClientCreditCards = clientCreditCards ;
            this.$ClientCreditCards.next(clientCreditCards );
          }
          return clientCreditCards ;
        },
          error => {
            console.log(error);
            this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
          }));

  }

  
    /**
     * Delete a credit card saved in file
     * 
     */
    deleteCreditCard(creditCardProfileID: number) {

      let params = new HttpParams();
      params = params.set("CreditCardProfileID", creditCardProfileID.toString());

      return this.http.delete(`${AppConfig.settings.apiServer.webApiURL}/ClientCreditCard/`, {
        params: params
      });
    }

  // Charge
      /**
        * 
      Get the list of the Credit Cards in file
        */
    public getCardConnectTokenIframe(){

      
      return this.http.get(`${AppConfig.settings.apiServer.webApiURL}/ClientCreditCard/CardConnectTokenIframe`, {
          responseType: 'text' 
          //responseType: 'arraybuffer',
          //observe: 'response' 
      })
        .pipe(tap(url => {
           //console.debug('CardConnectTokenIframe: '+ url);
            return url ;
        },
          error => {
            console.log(error);
            this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
          }));


  }
  
  
    /**
     * Charge a credit card
     * 
     * @param creditCardTransactionDetails 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public chargeCreditCard(creditCardTransactionDetails?: CreditCardTransactionModel) {

    
    return this.http.post<CreditCardReceiptModel>(`${AppConfig.settings.apiServer.webApiURL}/ClientCreditCard/`, creditCardTransactionDetails); 
      /*
      .pipe(tap(receipt => {
          return receipt;
        },
          error => {
            console.log(error);
            this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
          }));
          */

  }


  

    /**
     * 
    Get the list of the Payment Plans
     */  
    public getPatientPaymentPlans(): Observable<PatientPaymentPlanModel[]> {

      return this.http.get<PatientPaymentPlanModel[]>(`${AppConfig.settings.apiServer.webApiURL}/ClientCreditCard/PaymentPlans`, {  } )
        .pipe(tap(patientPaymentPlans  => {
          if (patientPaymentPlans ) {
            console.debug(patientPaymentPlans .length + ' Patient Payment Plans loaded');
            this.PatientPaymentPlans  = patientPaymentPlans ;
            this.$PatientPaymentPlans.next(patientPaymentPlans );
          }
          return patientPaymentPlans ;
        },
          error => {
            console.log(error);
            this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
          }));

  }


}
