<div class="container-fluid page">
  <app-nav-menu></app-nav-menu>
  <div class="d-flex flex-column container-fluid body">
    <app-alert></app-alert>
    <app-session-timer></app-session-timer>
    <div class="body-content p-3">
      <router-outlet></router-outlet>
    </div>
    <div class="p-1">
      <div class="col text-center" style="margin-top: 25px">
        <a [href]="generalSettings()?.businessWebsite" target="_blank">
          <img  [src]="generalSettings()?.businessLogoUrl || '/assets/img/OPTO Logo.png'" height="40" [alt]="generalSettings()?.businessFullName">
        </a>
        <br />
        <footer>
          <small>&copy; 2022 Meridian Technologies Inc. All Rights Reserved</small>
        </footer>
      </div>
    </div>
  </div>
</div>
