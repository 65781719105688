
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppConfig } from './core';
import { AppComponent } from './app.component';
import { CoreModule } from './core';
import { SharedModule } from './shared';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule, MainModule,PublicModule } from './modules';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}
declare global{
  interface Navigator{
     msSaveBlob:(blob: Blob,fileName:string) => boolean
     }
  }

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule.forRoot(),
    PublicModule,
    AuthModule,
    MainModule,
    AppRoutingModule,
    SharedModule,
    NgbModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
