import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, AccountService} from '../../../core';
import { LoginModel } from '../../../models/account';
import { ConfirmedValidator } from '../../../core/helpers/confirmed.validator';

@Component({ templateUrl: 'set-password.component.html'})
export class SetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  resetLoading = false;
  resetSubmitted = false;
  resetToken: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    
    this.resetToken = this.route.snapshot.queryParams['token'] || '';

    if ( this.resetToken == '') {
      this.router.navigate(['/']);
      return;
    }
    
    this.resetForm = this.formBuilder.group({
      password: new FormControl('', [Validators.required, Validators.maxLength(100), Validators.minLength(8)]),
      confirmPassword: new FormControl('', [Validators.required])
    }, {
      validator:  ConfirmedValidator('password', 'confirmPassword')
    });
  }

  // convenience getter for easy access to form fields
  get f2() { return this.resetForm.controls; }

  onResetSubmit() {
    this.resetSubmitted = true;

    // stop here if form is invalid
    if (this.resetForm.invalid) {
      return;
    }

    this.resetLoading = true;
    //this.accountService.currentUser.password = this.f2.password.value;
    var model: LoginModel = {
      ...this.accountService.currentUser,
      password: this.f2.password.value

    };
    this.accountService.update(model )
    .pipe(first())
    .subscribe(
      data => {
        this.alertService.success('Change successful', true);
        this.router.navigate(['/']);
      },
      error => {
        this.alertService.error(error);
          this.resetLoading = false;
      });
  }
}
