import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../config';
import { map, tap } from 'rxjs/operators';
import { Utils } from '../helpers/utils';
import { MessageService } from 'primeng/api';

import { InsuranceProviderModel, PatientInsuranceModel } from '../../models/patient';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class PatientInsurancesService {


  insuranceProviders: InsuranceProviderModel[];

  constructor(private http: HttpClient,
    private messageService: MessageService) {}


  getInsuranceProviders() {
    return this.http.get<InsuranceProviderModel[]>(
      `${AppConfig.settings.apiServer.webApiURL}/PatientInsurances/Providers`,
    ).pipe(map(insuranceProviders => {
      this.insuranceProviders = insuranceProviders;
    }));
  }

  newGetInsuranceProviders() {
    return this.http.get<InsuranceProviderModel[]>(`${AppConfig.settings.apiServer.webApiURL}/PatientInsurances/Providers`)
  }


  getPatientInsurances(inquiryID: number) {
    return this.http.get<PatientInsuranceModel[]>(
      `${AppConfig.settings.apiServer.webApiURL}/PatientInsurances/?inquiryID=${inquiryID}`,
    )
  }

  getPatientInsurance(patientHasInsuranceID: number) {
    return this.http.get<PatientInsuranceModel>(`${AppConfig.settings.apiServer.webApiURL}/PatientInsurance/${patientHasInsuranceID}`)
  }

  
  uploadInsuranceCard(inquiryID: number, file: File, patientHasInsuranceProviderID?: number, insuranceProviderID?: number, memberID?: string): Observable<any>  {

    
    var formData = new FormData();
    formData.append("inquiryID", inquiryID.toString());
    if (patientHasInsuranceProviderID)
      formData.append("patientHasInsuranceProviderID", patientHasInsuranceProviderID.toString());
    if (insuranceProviderID)
      formData.append("insuranceProviderID", insuranceProviderID.toString());
    formData.append("memberID", memberID);
    formData.append("file", file);

    return this.http.post(`${AppConfig.settings.apiServer.webApiURL}/PatientInsuranceCard/`,
      formData,
        {
        headers: {
           'enctype': 'multipart/form-data'
        }
        })
      .pipe(tap(data => {
        console.debug(file.name + ' uploaded. Id: ' + data.id);
        return data;
      },
      error => {
        console.log(error);
        this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
      }));
  }
  
  /* TODO
   *  GetPatientInsurances
   *  CreatePatientInsurance
   *  SetPatientInsurance
   *  DeletePatientInsurance
   */  

}
